import React, { useState } from 'react'
import { ReactComponent as Logo } from '../../../../assets/images/favicon.svg'
import Loader from '../../../../components/loader/Loader'
import { post } from '../../../../utilities/API/api'
import { useGlobalContext } from '../../../../utilities/Context/Context'

function CardModal({ notify }) {
  const { setShowCard } = useGlobalContext()
  const Token = sessionStorage.getItem('UserToken')
  const [purpose, setPurpose] = useState('')
  const [address, setAddress] = useState('')
  const [formLoading, setFormLoading] = useState(false)
  const [pin, setPin] = useState('')

  function submit() {
    if (purpose < 1) {
      notify('error', 'Purpose is compulsory')
    } else if (address < 1) {
      notify('error', 'Delivery Address is compulsory')
    } else {
      setFormLoading(true)
      const postData = {
        address: address,
        purpose: purpose,
      }
      post(`users/transaction-pin/verify`, { transactionPin: pin }, Token)
        .then((result) => {
          if (Object.keys(result).includes('error')) {
            notify('error', result.error)
            setFormLoading(false)
          } else {
            post(`users/card-request`, postData, Token)
              .then((result) => {
                if (Object.keys(result).includes('error')) {
                  notify('error', result.error)
                } else {
                  notify('success', 'Card request successful.')
                  setTimeout(() => {
                    window.location.reload()
                  }, 2000)
                }

                setFormLoading(false)
              })
              .catch((error) => {
                setFormLoading(false)
              })
          }
        })
        .catch((error) => {
          setFormLoading(false)
        })
    }
  }
  return (
    <div className='modal-type flex justify-center'>
      <div className='modal-body'>
        <div className='top '>
          <Logo />
          <h1>Request for Card.</h1>
          <p style={{ textAlign: 'center' }}>
            Credit card may take a bit of time to be processed and delivered.
            Please bear with us.
          </p>
        </div>
        <div className='form'>
          <div className='form-card'>
            <p>Purpose</p>
            <select name='' id='' onChange={(e) => setPurpose(e.target.value)}>
              <option value=''>- select - </option>
              <option value='Personal'>Personal</option>
              <option value='Business'>Business</option>
            </select>
          </div>
          <div className='form-card'>
            <p>Delivery address</p>
            <input
              type='text'
              name=''
              id=''
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div className='form-card'>
            <p>Transaction Pin</p>
            <input
              type='password'
              name='pin'
              id='pin'
              value={pin}
              maxLength={4}
              pattern='\d*'
              onChange={(e) => {
                if (/^\d*$/.test(e.target.value)) {
                  // Limit the input to 4 digits
                  setPin(e.target.value.slice(0, 4))
                }
              }}
            />
          </div>
          {formLoading ? (
            <Loader location='center' />
          ) : (
            <div className='form-card button'>
              <button onClick={() => setShowCard(false)} className='danger'>
                Cancel
              </button>
              <button onClick={submit}>Request card</button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CardModal
