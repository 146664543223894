const WealthCards = [
  {
    icon: 'heroicons:calendar-days',
    details: 'Plan your future',
  },
  {
    icon: 'fluent-emoji-high-contrast:potted-plant',
    details: 'Grow your wealth',
  },
  {
    icon: 'mdi:folder-key-outline',
    details: 'Preserve your wealth',
  },
  {
    icon: 'fluent-mdl2:reading-mode',
    details: 'Discover institutional trust',
  },
  {
    icon: 'dashicons:chart-line',
    details: 'Get our market outlook',
  },
  {
    icon: 'iwwa:lightbulb',
    details: 'Explore Insights',
  },
]

export default WealthCards
