import React, { useState } from 'react'
import { ReactComponent as Logo } from '../../../../assets/images/favicon.svg'
import Loader from '../../../../components/loader/Loader'
import { post } from '../../../../utilities/API/api'
import { useGlobalContext } from '../../../../utilities/Context/Context'
import { useParams } from 'react-router-dom'

function CreditUser({ notify }) {
  const { id } = useParams()
  const { setShowCreditUser } = useGlobalContext()
  const Token = sessionStorage.getItem('AdminToken')
  const [amount, setAmount] = useState(0)
  const [description, setDescription] = useState('')
  const [senderDetails, setSenderDetails] = useState('')
  const [formLoading, setFormLoading] = useState(false)
  function submit() {
    if (amount < 1) {
      notify('error', 'Amount is compulsory')
    } else if (description.length < 1) {
      notify('error', 'Description is compulsory')
    } else if (senderDetails.length < 1) {
      notify('error', 'Sender Detail is compulsory')
    } else {
      setFormLoading(true)
      const postData = {
        id: id,
        description: description,
        sender_details: senderDetails,
        amount: parseFloat(amount),
      }
      post(`admin/credit`, postData, Token)
        .then((result) => {
          if (Object.keys(result).includes('error')) {
            notify('error', result.error)
          } else {
            notify('success', 'User Credited.')
            setTimeout(() => {
              window.location.reload()
            }, 2000)
          }

          setFormLoading(false)
        })
        .catch((error) => {
          setFormLoading(false)
        })
    }
  }
  return (
    <div className='modal-type flex justify-center'>
      <div className='modal-body'>
        <div className='top '>
          <Logo />
          <h1>Credit User</h1>
        </div>
        <div className='form'>
          <div className='form-card'>
            <p>Amount</p>
            <input
              type='number'
              name=''
              id=''
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
          <div className='form-card'>
            <p>Sender Details</p>
            <input
              type='text'
              name=''
              id=''
              onChange={(e) => setSenderDetails(e.target.value)}
            />
          </div>
          <div className='form-card'>
            <p>Description</p>
            <input
              type='text'
              name=''
              id=''
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          {formLoading ? (
            <Loader location='center' />
          ) : (
            <div className='form-card button'>
              <button
                onClick={() => setShowCreditUser(false)}
                className='danger'
              >
                Cancel
              </button>
              <button onClick={submit}>Credit</button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CreditUser
