const CommercialCards = [
  {
    icon: 'fam-borrow',
    details: 'Credit & Financing',
  },
  {
    icon: 'fam-home-loans',
    details: 'Treasury Management Services',
  },
  {
    icon: 'fam-credit-cards',
    details: 'International Services',
  },
  {
    icon: 'lib-0961-city-skyscraper-flat',
    details: 'Middle Market Banking',
  },
  {
    icon: 'lib-0666-award-achievement-prize-medal',
    details: 'Industry Expertise',
  },
  {
    icon: 'fam-insights',
    details: 'Explore Insights',
  },
]

export default CommercialCards
