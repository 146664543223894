import React, { useEffect } from 'react'
import './UserDashboard.scss'
import Sidebar from './components/sidebar/Sidebar'
import { useParams } from 'react-router-dom'
import Topbar from './components/topbar/Topbar'
import { useGlobalContext } from '../../utilities/Context/Context'
import Home from './components/home/Home'
import Loader from '../../components/loader/Loader'
import Transactions from './components/transactions/Transactions'
import Receipt from './components/userModals/Receipt'
import SetPin from './components/userModals/SetPin'
import Transfer from './components/userModals/Transfer'
import Loan from './components/Loan/Loan'
import LoanModal from './components/userModals/LoanModal'
import Savings from './components/savings/Savings'
import SavingsModal from './components/userModals/SavingsModal'
import TransferSavings from './components/userModals/TransferSavings'
import Profile from './components/profile/Profile'
import ProfilePicture from './components/userModals/ProfilePicture'
import Cards from './components/cards/Cards'
import CardModal from './components/userModals/CardModal'
import Support from './components/support/Support'
import IdleTimerContainer from '../../utilities/IdleTimer/IdleTimer'

function UserDashboard({ notify }) {
  const { page } = useParams()
  const {
    closeOnOutsideClick,
    profileLoading,
    fetchUser,
    receiptData,
    userProfile,
    initiateTransfer,
    showLoan,
    showSavings,
    showTransferSavings,
    showProfileImage,
    showCard,
  } = useGlobalContext()

  const dashboardPage = () => {
    switch (page) {
      case 'home':
        return <Home />
      case 'transactions':
        return <Transactions />
      case 'loan':
        return <Loan notify={notify} />
      case 'savings':
        return <Savings notify={notify} />
      case 'profile':
        return <Profile notify={notify} />
      case 'cards':
        return <Cards notify={notify} />
      case 'support':
        return <Support notify={notify} />

      default:
        return <h1>Page not found</h1>
    }
  }
  useEffect(() => {
    fetchUser()
    // eslint-disable-next-line
  }, [])
  return (
    <div className='user-dashboard'>
      <IdleTimerContainer />
      {receiptData && <Receipt notify={notify} />}
      {!profileLoading && userProfile.securityQuestions.length < 1 && (
        <SetPin notify={notify} />
      )}
      {initiateTransfer && <Transfer notify={notify} />}
      {showLoan && <LoanModal notify={notify} />}
      {showSavings && <SavingsModal notify={notify} />}
      {showTransferSavings && <TransferSavings notify={notify} />}
      {showProfileImage && <ProfilePicture notify={notify} />}
      {showCard && <CardModal notify={notify} />}
      <div className='main-body'>
        <Sidebar page={page} notify={notify} />
        <div className='main_container' onClick={closeOnOutsideClick}>
          {profileLoading ? (
            <Loader location='center' />
          ) : (
            <>
              <Topbar page={page} />
              <div className='dashboard-rest'>{dashboardPage()}</div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default UserDashboard
