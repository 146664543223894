import React, { useState } from 'react'
import { ReactComponent as Logo } from '../../../../assets/images/favicon.svg'
import { useGlobalContext } from '../../../../utilities/Context/Context'
import html2pdf from 'html2pdf.js'

function Receipt() {
  const { receiptData, setReceiptData } = useGlobalContext()
  const [downloading, setDownloading] = useState(false)

  const generatePDF = (elementId, fileName) => {
    const element = document.getElementById(elementId)
    html2pdf(element, {
      filename: fileName,
      margin: 10,
      html2canvas: { scale: 2 },
      image: { type: 'jpeg', quality: 0.98 },
    })
  }
  function submit() {
    setDownloading(true)
    generatePDF(
      'receiptContainer',
      `receipt - ${
        new Date(receiptData.createdAt).toDateString() +
        ' ' +
        new Date(receiptData.createdAt).toLocaleTimeString()
      }.pdf`
    )
    setTimeout(() => {
      setDownloading(false)
    }, 3000)
  }

  return (
    <div
      className='modal-type dif justify-center '
      id='receiptContainer'
      style={{
        backgroundColor: '#0000005a',
        width: '100%',
        height: '100%',
        padding: '100px 0',
      }}
    >
      <div
        className='modal-body'
        style={{
          backgroundColor: '#fff',
          maxWidth: '500px',
          width: '100%',
          borderRadius: '10px',
        }}
      >
        <div className='top' style={{ textAlign: 'center', padding: '20px' }}>
          <Logo style={{ width: '50px', marginBottom: '10px' }} />
          <h1>Receipt</h1>
        </div>
        <div
          className='form'
          style={{
            padding: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <div
            className='form-card'
            style={{ marginBottom: '10px', width: '100%' }}
          >
            <p
              style={{
                paddingBottom: '5px',
                fontSize: '14px',
                fontWeight: '600',
                color: '#2e2e2e',
              }}
            >
              Amount
            </p>
            <div
              className='input'
              style={{
                width: '100%',
                lineHeight: 'normal',
                padding: '10px',
                backgroundColor: '#e1e1e1',
                color: '#2e2e2e',
                borderRadius: '3px',
              }}
            >
              <h4 style={{ fontSize: '14px' }}>
                ${receiptData.amount.toLocaleString()}
              </h4>
            </div>
          </div>
          <div
            className='form-card'
            style={{ marginBottom: '10px', width: '100%' }}
          >
            <p
              style={{
                paddingBottom: '5px',
                fontSize: '14px',
                fontWeight: '600',
                color: '#2e2e2e',
              }}
            >
              Status
            </p>
            <div
              className='input'
              style={{
                width: '100%',
                lineHeight: 'normal',
                padding: '10px',
                backgroundColor: '#e1e1e1',
                color: '#2e2e2e',
                borderRadius: '3px',
              }}
            >
              <h4 style={{ fontSize: '14px' }}>{receiptData.state}</h4>
            </div>
          </div>
          <div
            className='form-card'
            style={{ marginBottom: '10px', width: '100%' }}
          >
            <p
              style={{
                paddingBottom: '5px',
                fontSize: '14px',
                fontWeight: '600',
                color: '#2e2e2e',
              }}
            >
              Type
            </p>
            <div
              className='input'
              style={{
                width: '100%',
                lineHeight: 'normal',
                padding: '10px',
                backgroundColor: '#e1e1e1',
                color: '#2e2e2e',
                borderRadius: '3px',
              }}
            >
              <h4 style={{ fontSize: '14px' }}>{receiptData.type}</h4>
            </div>
          </div>
          {receiptData.type === 'credit' ? (
            <div
              className='form-card'
              style={{ marginBottom: '10px', width: '100%' }}
            >
              <p
                style={{
                  paddingBottom: '5px',
                  fontSize: '14px',
                  fontWeight: '600',
                  color: '#2e2e2e',
                }}
              >
                Sender details
              </p>
              <div
                className='input'
                style={{
                  width: '100%',
                  lineHeight: 'normal',
                  padding: '10px',
                  backgroundColor: '#e1e1e1',
                  color: '#2e2e2e',
                  borderRadius: '3px',
                }}
              >
                <h4 style={{ fontSize: '14px' }}>
                  {receiptData.sender_details}
                </h4>
              </div>
            </div>
          ) : (
            <>
              <div
                className='form-card'
                style={{ marginBottom: '10px', width: '100%' }}
              >
                <p
                  style={{
                    paddingBottom: '5px',
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#2e2e2e',
                  }}
                >
                  Recipient Full Name
                </p>
                <div
                  className='input'
                  style={{
                    width: '100%',
                    lineHeight: 'normal',
                    padding: '10px',
                    backgroundColor: '#e1e1e1',
                    color: '#2e2e2e',
                    borderRadius: '3px',
                  }}
                >
                  <h4 style={{ fontSize: '14px' }}>
                    {receiptData.recipeint_name}
                  </h4>
                </div>
              </div>
              <div
                className='form-card'
                style={{ marginBottom: '10px', width: '100%' }}
              >
                <p
                  style={{
                    paddingBottom: '5px',
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#2e2e2e',
                  }}
                >
                  Recipient Bank name
                </p>
                <div
                  className='input'
                  style={{
                    width: '100%',
                    lineHeight: 'normal',
                    padding: '10px',
                    backgroundColor: '#e1e1e1',
                    color: '#2e2e2e',
                    borderRadius: '3px',
                  }}
                >
                  <h4 style={{ fontSize: '14px' }}>
                    {receiptData.recipeint_bank_name}
                  </h4>
                </div>
              </div>
              <div
                className='form-card'
                style={{ marginBottom: '10px', width: '100%' }}
              >
                <p
                  style={{
                    paddingBottom: '5px',
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#2e2e2e',
                  }}
                >
                  Recipient Account number
                </p>
                <div
                  className='input'
                  style={{
                    width: '100%',
                    lineHeight: 'normal',
                    padding: '10px',
                    backgroundColor: '#e1e1e1',
                    color: '#2e2e2e',
                    borderRadius: '3px',
                  }}
                >
                  <h4 style={{ fontSize: '14px' }}>
                    {receiptData.account_number}
                  </h4>
                </div>
              </div>
            </>
          )}
          <div
            className='form-card'
            style={{ marginBottom: '10px', width: '100%' }}
          >
            <p
              style={{
                paddingBottom: '5px',
                fontSize: '14px',
                fontWeight: '600',
                color: '#2e2e2e',
              }}
            >
              Transaction Date
            </p>
            <div
              className='input'
              style={{
                width: '100%',
                lineHeight: 'normal',
                padding: '10px',
                backgroundColor: '#e1e1e1',
                color: '#2e2e2e',
                borderRadius: '3px',
              }}
            >
              <h4 style={{ fontSize: '14px' }}>
                {new Date(receiptData.createdAt).toDateString() +
                  ' ' +
                  new Date(receiptData.createdAt).toLocaleTimeString()}
              </h4>
            </div>
          </div>
          <div
            className='form-card'
            style={{ marginBottom: '10px', width: '100%' }}
          >
            <p
              style={{
                paddingBottom: '5px',
                fontSize: '14px',
                fontWeight: '600',
                color: '#2e2e2e',
              }}
            >
              Transaction number
            </p>
            <div
              className='input'
              style={{
                width: '100%',
                lineHeight: 'normal',
                padding: '10px',
                backgroundColor: '#e1e1e1',
                color: '#2e2e2e',
                borderRadius: '3px',
              }}
            >
              <h4 style={{ fontSize: '14px' }}>
                {receiptData.transaction_number}
              </h4>
            </div>
          </div>
          <div
            className='form-card'
            style={{ marginBottom: '10px', width: '100%' }}
          >
            <p
              style={{
                paddingBottom: '5px',
                fontSize: '14px',
                fontWeight: '600',
                color: '#2e2e2e',
              }}
            >
              Description
            </p>
            <div
              className='input'
              style={{
                width: '100%',
                lineHeight: 'normal',
                padding: '10px',
                backgroundColor: '#e1e1e1',
                color: '#2e2e2e',
                borderRadius: '3px',
              }}
            >
              <h4 style={{ fontSize: '14px' }}>{receiptData.description}</h4>
            </div>
          </div>
          {!downloading && (
            <div className='form-card button' style={{ width: '100%' }}>
              <button className='danger' onClick={() => setReceiptData(null)}>
                Close
              </button>
              <button onClick={submit}>Download</button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Receipt
