import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './assets/styles/_resources.scss'
import { AppProvider } from './utilities/Context/Context'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>
)
