import React, { useState } from 'react'
import NavBar from '../../components/navBar/NavBar'
import './HomePage.scss'
import Icons from '../../assets/images/icons.svg'
import PersonalCards from '../../assets/data/PersonalCards'
import personalImage from '../../assets/images/personal-mobile.jpeg'
import Phone from '../../assets/images/Phone.png'
import PersonalInsights from '../../assets/data/PersonalInsights'
import Footer from '../../components/footer/Footer'
import Forever from './Forever'
import Anniversary from './Anniversary'
import Insights from './Insights'
import Disclosures from './Disclosures'
import OpenAccount from './OpenAccount'
import { useNavigate } from 'react-router-dom'
import HomeLoan from '../../components/footer/popover/HomeLoan'
import CreditCard from '../../components/footer/popover/CreditCard'
function HomePage() {
  const navigate = useNavigate()
  const [showHomeLoan, setShowHomeLoan] = useState(false)
  const [creditCard, setCreditCard] = useState(false)

  return (
    <div className='home-page'>
      {showHomeLoan && <HomeLoan setClose={setShowHomeLoan} />}
      {creditCard && <CreditCard setClose={setCreditCard} />}
      <NavBar />
      <div className='hero justify-center'>
        <div className='hero-cover'>
          <div className='image-container'>
            <img src={personalImage} alt='' />
          </div>
          <div className='text-container'>
            <div className='fade-der'></div>
            <h1>Earn 5.00% APY with a new 11-month high-yield CD</h1>
            <div className='flex flex-wrap'>
              <button
                className='button-bold'
                onClick={() => navigate('/sign-up')}
              >
                Open now
              </button>
              <button
                className='button-text'
                onClick={() => navigate('/login')}
              >
                Grow Strong
              </button>
            </div>
          </div>
          <div className='flex justify-between flex-wrap cards-wrapper'>
            {PersonalCards.map((item) => (
              <div
                className='card'
                key={item.icon}
                onClick={() => navigate('/login')}
              >
                <svg>
                  <use xlinkHref={`${Icons}#${item.icon}`} />
                </svg>
                <h3>{item.details}</h3>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='ai-banner justify-center'>
        <div className='ai-cover flex justify-between flex-wrap'>
          <h1>Discover how generative AI impacts our lives</h1>
          <button
            className='button-outline '
            onClick={() => navigate('/sign-up')}
          >
            Sign up
          </button>
        </div>
      </div>
      <div className='loaner justify-center'>
        <div className='cards-cover flex justify-between flex-wrap'>
          <div className='card'>
            <div className='rest flex'>
              <svg>
                <use xlinkHref={`${Icons}#cta-buy-home`} />
              </svg>
              <p className='tag'>Home Improvement Loans</p>
              <h2>Fast access to funds for your remodel project</h2>
              <p className='quick'>
                A quick and easy application process to remodel without using
                your home as collateral.
              </p>
              <button
                className='button-text white'
                onClick={() => setShowHomeLoan(true)}
              >
                Learn More
              </button>
            </div>
          </div>
          <div className='card green'>
            <div className='rest flex'>
              <svg>
                <use xlinkHref={`${Icons}#fam-credit-cards`} />
              </svg>
              <p className='tag'>Credit Cards</p>
              <h2>Now it's even easier to earn more</h2>
              <p className='quick'>
                More cash back. More rewards. More travel experiences. With our
                newly improved credit cards, the choice is yours.
              </p>
              <button
                className='button-text white'
                onClick={() => setCreditCard(true)}
              >
                Learn More
              </button>
            </div>
          </div>
        </div>
      </div>
      <Anniversary />
      <div className='track justify-center'>
        <div className='track-cover flex justify-center flex-wrap'>
          <div className='left'>
            <img src={Phone} alt='' />
          </div>
          <div className='right'>
            <h3>DIGITAL BANKING</h3>
            <div>
              <h2>Track your spending habits</h2>
            </div>
            <div>
              <h2>Seamlessly move your money</h2>
            </div>
            <div>
              <h2>Set alerts for transactions</h2>
            </div>
            <button className='button-text' onClick={() => navigate('/login')}>
              Learn More
            </button>
          </div>
        </div>
      </div>
      <div className='ai-banner justify-center'>
        <div className='ai-cover first flex justify-between flex-wrap'>
          <h1>Mint Capital partners with Silicon Valley Bridge Bank</h1>
          <button
            className='button-outline button-green'
            onClick={() => navigate('/sign-up')}
          >
            Sign up
          </button>
        </div>
      </div>
      <OpenAccount />
      <Forever />
      <Insights
        content='A few financial insights for your life'
        data={PersonalInsights}
      />
      <Disclosures />
      <Footer />
    </div>
  )
}

export default HomePage
