import React from 'react'

function RoutingNumber({ setClose }) {
  return (
    <div className='popup'>
      <div className='pop-cover'>
        <div className='tittle'>
          <h1>Access your account information with ease</h1>
        </div>
        <p>
          Routing numbers are often referred to as check routing numbers, ABA
          numbers or routing numbers (RTN). Routing numbers may vary by the
          location where your account was opened and the type of transaction
          made.
        </p>
        <p>
          Once logged in, select a specific account, and then the Details tab.
          Choose the Click Here link under Routing and Account Numbers.
        </p>
        <div className='button'>
          <button onClick={() => setClose(false)}>close</button>
        </div>
      </div>
    </div>
  )
}

export default RoutingNumber
