import React, { useState } from 'react'
import './auth.scss'
import Logo from '../../assets/images/logo.png'
import { useNavigate, useParams } from 'react-router-dom'
import { putNo } from '../../utilities/API/api'
import Loader from '../../components/loader/Loader'

function ResetPassword({ notify }) {
  const { email } = useParams()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [otp, setOtp] = useState('')
  const [formLoading, setFormLoading] = useState(false)
  const navigate = useNavigate()
  function submit() {
    if (otp.length !== 4) {
      notify('error', 'Incorrect OTP')
    } else if (
      password.length < 8 ||
      !/[0-9]/.test(password) ||
      !/[A-Z]/.test(password) ||
      !/[a-z]/.test(password) ||
      !/[!@#$%^&*(),.?":{}|<>]/.test(password)
    ) {
      notify(
        'error',
        'Password must contain at least one number, one uppercase, one lowercase and one special character'
      )
    } else if (password !== confirmPassword) {
      notify('error', `Passwords didn't match`)
    } else {
      setFormLoading(true)
      const postData = {
        email: email,
        password: password,
        otp: otp,
      }
      putNo('users/otp/verify', postData)
        .then((result) => {
          if (Object.keys(result).includes('error')) {
            notify('error', result.error)
          } else {
            notify('success', 'Password changed successfully.')
            setTimeout(() => {
              navigate('/login')
            }, 2000)
          }
          setFormLoading(false)
        })
        .catch((error) => {
          setFormLoading(false)
        })
    }
  }
  return (
    <div className='flex justify-center auth'>
      <div className='modal'>
        <div className='modal-wrap'>
          <div className='top'>
            <img src={Logo} alt='' onClick={() => navigate('/')} />
          </div>
          <div className='form'>
            <div className='form-cover'>
              <div className='form-card'>
                <p>OTP</p>
                <input
                  type='tel'
                  name=''
                  id=''
                  onChange={(e) => setOtp(e.target.value)}
                />
              </div>
              <div className='form-card'>
                <p>Password</p>
                <input
                  type='password'
                  name=''
                  id=''
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className='form-card'>
                <p>Confirm password</p>
                <input
                  type='password'
                  name=''
                  id=''
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              {formLoading ? (
                <Loader location='center' />
              ) : (
                <div className='form-card'>
                  <button onClick={submit} className='submit'>
                    Reset Password
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
