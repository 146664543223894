import React from 'react'

function Forever({ setClose }) {
  return (
    <div className='popup'>
      <div className='pop-cover'>
        <div className='tittle'>
          <h1>Forever starts with the first 125 years</h1>
        </div>
        <p>
          Mint capital alliance was founded on the values of building meaningful
          relationships, delivering excellent customer service and focusing on
          long-term results. For 125 years, we've been devoted to putting
          customers first—we've done it together. Here's to 125 more years of
          continuing our tradition.
        </p>
        <p>
          Our customers entrust us with major life moments—things like
          purchasing their first home, starting a family business and expanding
          generational farms. This responsibility allows us to help the
          communities we serve sustain and grow their local economies—something
          we can all celebrate.
        </p>
        <p>
          We’re also proud to support causes and initiatives our customers and
          employees can get behind. From lending to and investing in low- to
          moderate- income neighborhoods through our Community Benefits Plan to
          raising critical awareness and substantial funding for Teen Cancer
          America hospitals, we strive every day to put our platform to good
          use.
        </p>
        <div className='button'>
          <button onClick={() => setClose(false)}>close</button>
        </div>
      </div>
    </div>
  )
}

export default Forever
