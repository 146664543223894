import React from 'react'

function Terms({ setClose }) {
  return (
    <div className='popup'>
      <div className='pop-cover'>
        <div className='tittle'>
          <h1>Supporting Our Communities</h1>
        </div>
        <p>
          Mint capital alliance is a member of FDIC and an Equal Housing Lender.
          Mint capital alliance provides banking services in Arizona,
          California, Colorado, Florida, Georgia, Kansas, Maryland, Missouri,
          New Mexico, North Carolina, Oklahoma, Oregon, South Carolina,
          Tennessee, Texas, Virginia, Washington and West Virginia. The
          information contained in this website is not intended as an offer or
          solicitation of banking services to customers in any state in which
          the offering, solicitation, or providing of such services is
          prohibited by federal law or the laws of the state of the customer's
          residence. Any loans made to parties outside Arizona, California,
          Colorado, Florida, Georgia, Kansas, Maryland, Missouri, New Mexico,
          North Carolina, Oklahoma, Oregon, South Carolina, Tennessee, Texas,
          Virginia, Washington and West Virginia as a result of the information
          contained herein shall be approved in and disbursed from Mint capital
          alliance's branches in Arizona, California, Colorado, Florida,
          Georgia, Kansas, Maryland, Missouri, New Mexico, North Carolina,
          Oklahoma, Oregon, South Carolina, Tennessee, Texas, Virginia,
          Washington or West Virginia. As used below, "you" refers to the user
          of this website, whether or not a customer of Mint capital alliance.
          "We" refers to Mint capital alliance Bank. All information on this
          website is protected by a compilation copyright in the United States
          of America and in other countries. In addition, certain other
          information is copyrighted by others. Unless otherwise specified, no
          one has permission to copy or republish, in any form, any information
          found on this website or the Mint capital alliance system.
        </p>
        <p>
          Mint capital alliance grants you only a limited, nonexclusive,
          revocable right to access and make non-commercial use of this website
          for your own personal or business purposes only. Subject to your
          customer agreements with us, Mint capital alliance reserves the right
          to deny you access to this website at any time, to change any and all
          parts of the website, and to remove or replace this website, in each
          case without notice to you. You must not modify, copy, reuse,
          distribute, display, download, or perform any part of this website
          except as necessary for you to view or use this website in the course
          of your business with us, although you may print a single copy of the
          web pages in this website for your own personal or business use and
          your computer or mobile device may cache web pages in memory under
          standard web browser operations. You must not use any framing, data
          mining, robots, linking, or similar devices in any way with respect to
          this website without our consent. Your unauthorized use of this
          website or our trademarks, copyrights, or other proprietary rights,
          including any commercial use, automatically terminates your license to
          use this website, without any requirement for notice and whether or
          not we take any action against you.
        </p>
        <div className='button'>
          <button onClick={() => setClose(false)}>close</button>
        </div>
      </div>
    </div>
  )
}

export default Terms
