import React, { useEffect, useState } from 'react'
import { useGlobalContext } from '../../../../utilities/Context/Context'
import Loader from '../../../../components/loader/Loader'
import Pagination from '../../../../components/pagination/Pagination'
import { Icon } from '@iconify/react'

function Transactions() {
  const {
    fetchUserTransactions,
    userTransactionsLoading,
    userTransactions,
    setUserTransactionsLoading,
    setReceiptData,
    setInitiateTransfer,
  } = useGlobalContext()
  const [currentPage, setCurrentPage] = useState(1)
  const paginationClick = (num) => {
    setUserTransactionsLoading(true)
    fetchUserTransactions(num)
  }

  useEffect(
    () => {
      fetchUserTransactions(1)
    },
    // eslint-disable-next-line
    []
  )
  return (
    <div className='user-home'>
      {userTransactionsLoading ? (
        <Loader location='center' />
      ) : (
        <>
          <div className='buttons-head'>
            <button onClick={() => setInitiateTransfer(true)}>Transfer</button>
          </div>
          <div className='home_tables'>
            <div className='left' style={{ minHeight: '60vh' }}>
              <table>
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Amount</th>
                    <th>Type</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {userTransactions.transactions.map((item, i) => (
                    <tr key={item._id}>
                      <td>{i + 1}</td>
                      <td>${item.amount.toLocaleString()}</td>
                      <td className='count'>{item.type}</td>
                      <td className='count'>
                        {new Date(item.createdAt).toDateString()}, {''}
                        {new Date(item.createdAt).toLocaleTimeString()}
                      </td>
                      <td>{item.state}</td>
                      <td>
                        {' '}
                        <Icon
                          icon='fluent:open-24-filled'
                          onClick={() => setReceiptData(item)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className='mobile-table'>
                {userTransactions.transactions.map((item, i) => (
                  <div className='row' key={item._id}>
                    <p>
                      <b>S/N</b> : {i + 1}
                    </p>
                    <p>
                      <b>Amount</b> : ${item.amount.toLocaleString()}
                    </p>
                    <p>
                      <b>Type</b> : {item.type}
                    </p>
                    <p>
                      <b>Date</b> : {new Date(item.createdAt).toDateString()},{' '}
                      {new Date(item.createdAt).toLocaleTimeString()}
                    </p>
                    <p>
                      <b>Status</b> : {item.state}
                    </p>
                    <button onClick={() => setReceiptData(item)}>
                      view receipt
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Pagination
            currentPage={currentPage}
            totalCount={userTransactions.totalPages}
            pageSize={10}
            onPageChange={(page) => {
              setCurrentPage(page)
              paginationClick(page)
            }}
          />
        </>
      )}
    </div>
  )
}

export default Transactions
