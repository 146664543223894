import React, { useState, useEffect } from 'react'
import './User.scss'
import { useGlobalContext } from '../../../../utilities/Context/Context'
import { get } from '../../../../utilities/API/api'
import { useParams } from 'react-router-dom'
import Loader from '../../../../components/loader/Loader'
import { Icon } from '@iconify/react'
import Pagination from '../../../../components/pagination/Pagination'

function User() {
  const { setShowCreditUser, setReceiptData, setRestrictUser } =
    useGlobalContext()
  const [userData, setUserData] = useState({})
  const Token = sessionStorage.getItem('AdminToken')
  const [loading, setLoading] = useState(true)

  const { id } = useParams()
  useEffect(() => {
    get(`admin/users/${id}`, Token).then((result) => {
      setUserData(result.user)
      setLoading(false)
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    FetchTransactions(1)
    FetchSavings(1)
    FetchTransactionsSavings(1)
    // eslint-disable-next-line
  }, [])
  //////Transacts
  const [transactionsData, setTransactionsData] = useState({})
  const [transactionsLoading, setTransactionsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  function FetchTransactions(page) {
    setTransactionsLoading(true)
    get(`admin/transfer/history/${id}?pageSize=10&page=${page}`, Token).then(
      (result) => {
        setTransactionsData(result)
        setTransactionsLoading(false)
      }
    )
  }
  const paginationClick = (num) => {
    FetchTransactions(num)
  }
  // Savings
  const [savingsData, setSavingsData] = useState({})
  const [savingsLoading, setSavingsLoading] = useState(true)
  const [currentPage2, setCurrentPage2] = useState(1)
  function FetchSavings(page) {
    setSavingsLoading(true)
    get(`admin/savings/history/${id}?pageSize=10&page=${page}`, Token).then(
      (result) => {
        setSavingsData(result)
        setSavingsLoading(false)
      }
    )
  }
  const paginationClick2 = (num) => {
    FetchSavings(num)
  }
  // Savings Transaction
  const [savingsTransactionsData, setSavingsTransactionsData] = useState({})
  const [savingsTransactionsLoading, setSavingsTransactionsLoading] =
    useState(true)
  const [currentPage3, setCurrentPage3] = useState(1)
  function FetchTransactionsSavings(page) {
    setSavingsTransactionsLoading(true)
    get(
      `admin/savings-transactions/history/${id}?pageSize=10&page=${page}`,
      Token
    ).then((result) => {
      setSavingsTransactionsData(result)
      setSavingsTransactionsLoading(false)
    })
  }
  const paginationClick3 = (num) => {
    FetchTransactionsSavings(num)
  }

  return loading ? (
    <Loader location='center' />
  ) : (
    <div className='admin-user'>
      <div className='buttons-head'>
        <button onClick={() => setShowCreditUser(true)}>Credit User</button>
      </div>
      <div className='details'>
        <div className='det-top'>
          <h2>Personal Details</h2>
        </div>
        <div className='flex justify-between flex-wrap'>
          <div className='form-card'>
            <p>First name</p>
            <div className='input'>
              <h4>{userData.firstName}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Last name</p>
            <div className='input'>
              <h4>{userData.lastName}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>D.O.B</p>
            <div className='input'>
              <h4>{new Date(userData.dob).toDateString()}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Phone</p>
            <div className='input'>
              <h4>{userData.phone}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Email</p>
            <div className='input'>
              <h4>{userData.email}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Nationality</p>
            <div className='input'>
              <h4>{userData.nationality}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>SSN</p>
            <div className='input'>
              <h4>{userData.ssn.length > 1 ? userData.ssn : 'N/A'}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Address</p>
            <div className='input'>
              <h4>{userData.address} </h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Id Front Image</p>
            <div className='input'>
              <a href={userData.idFront} target='_blank' rel='noreferrer'>
                <button>View Image</button>
              </a>
            </div>
          </div>
          <div className='form-card'>
            <p>Id Back Image</p>
            <div className='input'>
              <a href={userData.idBack} target='_blank' rel='noreferrer'>
                <button>View Image</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='details'>
        <div className='det-top'>
          <h2>Account Details</h2>
        </div>
        <div className='flex justify-between flex-wrap'>
          <div className='form-card'>
            <p>Account number</p>
            <div className='input'>
              <h4>{userData.account_number}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Routing number</p>
            <div className='input'>
              <h4>{userData.routing_number}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Swift code</p>
            <div className='input'>
              <h4>{userData.swift_code}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Account Balance</p>
            <div className='input'>
              <h4>${userData.balance.toLocaleString()}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Loan Balance</p>
            <div className='input'>
              <h4>${userData.loan_balance.toLocaleString()}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Savings Balance</p>
            <div className='input'>
              <h4>${userData.savings_balance.toLocaleString()}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Banned</p>
            <div className='input'>
              <h4>{userData.banned ? 'True' : 'False'}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Restricted</p>
            <div className='input'>
              <h4>{userData.restricted ? 'True' : 'False'}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Restrict user</p>
            <div className='input'>
              {userData.restricted ? (
                <button onClick={() => setRestrictUser(false)}>
                  Un-restrict
                </button>
              ) : (
                <button onClick={() => setRestrictUser(true)}>Restrict</button>
              )}
            </div>
          </div>
        </div>
      </div>
      {transactionsLoading ? (
        <Loader location='center' />
      ) : (
        <div className='details'>
          <div className='det-top'>
            <h2>Transactions</h2>
          </div>
          <table>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Date</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {transactionsData.transactions.map((item, i) => (
                <tr key={item._id}>
                  <td>{i + 1}</td>
                  <td>${item.amount.toLocaleString()}</td>
                  <td className='count'>{item.type}</td>
                  <td className='count'>
                    {new Date(item.createdAt).toDateString()}, {''}
                    {new Date(item.createdAt).toLocaleTimeString()}
                  </td>
                  <td>{item.state}</td>
                  <td>
                    <Icon
                      icon='fluent:open-24-filled'
                      onClick={() => setReceiptData(item)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            currentPage={currentPage}
            totalCount={transactionsData.totalPages}
            pageSize={10}
            onPageChange={(page) => {
              setCurrentPage(page)
              paginationClick(page)
            }}
          />
        </div>
      )}
      {savingsLoading ? (
        <Loader location='center' />
      ) : (
        <div className='details'>
          <div className='det-top'>
            <h2>Savings history</h2>
          </div>
          <table>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Amount</th>
                <th>Days left</th>
                <th>Daily amount</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {savingsData.savings.map((item, i) => (
                <tr key={item._id}>
                  <td>{i + 1}</td>
                  <td>${item.amount.toLocaleString()}</td>
                  <td>{item.savingsDurationDays}</td>
                  <td>${item.savingsDailyAmount.toLocaleString()}</td>
                  <td className='count'>
                    {new Date(item.createdAt).toDateString()}, {''}
                    {new Date(item.createdAt).toLocaleTimeString()}
                  </td>
                  <td>{item.isActive ? 'Running' : 'Ended'}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            currentPage={currentPage2}
            totalCount={savingsData.totalPages}
            pageSize={10}
            onPageChange={(page) => {
              setCurrentPage2(page)
              paginationClick2(page)
            }}
          />
        </div>
      )}
      {savingsTransactionsLoading ? (
        <Loader location='center' />
      ) : (
        <div className='details'>
          <div className='det-top'>
            <h2>Savings history</h2>
          </div>
          <table>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Credited to</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {savingsTransactionsData.transactions.map((item, i) => (
                <tr key={item._id}>
                  <td>{i + 1}</td>
                  <td>${item.amount.toLocaleString()}</td>
                  <td>{item.type}</td>
                  <td>{item.credited_to}</td>
                  <td className='count'>
                    {new Date(item.createdAt).toDateString()}, {''}
                    {new Date(item.createdAt).toLocaleTimeString()}
                  </td>
                  <td>{item.state}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            currentPage={currentPage3}
            totalCount={savingsTransactionsData.totalPages}
            pageSize={10}
            onPageChange={(page) => {
              setCurrentPage3(page)
              paginationClick3(page)
            }}
          />
        </div>
      )}
    </div>
  )
}

export default User
