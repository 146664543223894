import React, { useState } from 'react'
import './auth.scss'
import Logo from '../../assets/images/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import { postNo } from '../../utilities/API/api'
import Loader from '../../components/loader/Loader'

function Login({ notify, Login }) {
  const [emailAddress, setEmailAddress] = useState('')
  const [password, setPassword] = useState('')
  const [formLoading, setFormLoading] = useState(false)
  const navigate = useNavigate()
  function submit() {
    if (emailAddress.length < 1) {
      notify('error', 'Email address is compulsory')
    } else {
      setFormLoading(true)
      const postData = {
        email: emailAddress,
        password: password,
      }
      postNo('user/login', postData)
        .then((result) => {
          if (Object.keys(result).includes('error')) {
            notify('error', result.error)
          } else {
            if (!Object.keys(result.user).includes('logged_in_before')) {
              notify('warn', 'Redirecting... Please change default password')
              postNo('users/otp/request', {
                email: result.user.email,
              }).then((data) => {
                if (Object.keys(data).includes('error')) {
                  notify('error', data.error)
                } else {
                  navigate(`/reset-password/${result.user.email}`)
                }
              })
            } else {
              notify('success', `Welcome  ${result.user.firstName}`)
              Login(result.token)
              setTimeout(() => {
                window.location.replace('/user/home')
              }, 2000)
            }
          }
          setFormLoading(false)
        })
        .catch((error) => {
          notify('error', 'Server corrupted, contact your service provider.')
          setFormLoading(false)
        })
    }
  }
  return (
    <div className='flex justify-center auth'>
      <div className='modal'>
        <div className='modal-wrap'>
          <div className='top'>
            <img src={Logo} alt='' onClick={() => navigate('/')} />
          </div>
          <div className='form'>
            <div className='form-cover'>
              <div className='form-card'>
                <p>Login ID</p>
                <input
                  type='email'
                  name=''
                  id='email'
                  onChange={(e) => setEmailAddress(e.target.value)}
                />
              </div>
              <div className='form-card'>
                <p>Password</p>
                <input
                  type='password'
                  name=''
                  id='password'
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {formLoading ? (
                <Loader location='center' />
              ) : (
                <div className='form-card'>
                  <button onClick={submit} className='submit'>
                    Log In
                  </button>
                </div>
              )}
              <div className='flex justify-between'>
                <Link to={'/sign-up'}>
                  <p>Sign Up</p>
                </Link>
                <Link to={'/forget-password'}>
                  <p>Forgot password?</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
