import React from 'react'

function Disclosures() {
  return (
    <div className='disclosures justify-center'>
      <div className='disc-cover'>
        <h2>Disclosures</h2>
        <p>
          This information is provided for educational purposes only and should
          not be relied on or interpreted as accounting, financial planning,
          investment, legal or tax advice. Mint Capital Alliance (or its
          affiliates) neither endorses nor guarantees this information, and
          encourages you to consult a professional for advice applicable to your
          specific situation. <br /> <br /> Third parties mentioned are not
          affiliated with Mint Capital Alliance Company. <br />
          <br />
          APY (annual percentage yield) is accurate as of December 1, 2023.
          Penalty may be imposed for early withdrawal. No brokered CDs accepted.
          Other restrictions and fees may apply. Fees could reduce the earnings
          on the account. <br /> <br /> Links to third-party websites may have a
          privacy policy different from Mint Capital Alliance and may provide
          less security than this website. Mint Capital Alliance and its
          affiliates are not responsible for the products, services and content
          on any third-party website. <br /> <br /> Bank deposit products are
          offered by Mint Capital Alliance. Member FDIC and an Equal Housing
          Lender. <br /> <br /> NMLSR ID 503941
        </p>
      </div>
    </div>
  )
}

export default Disclosures
