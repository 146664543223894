import React from 'react'

function HomeLoan({ setClose }) {
  return (
    <div className='popup'>
      <div className='pop-cover'>
        <div className='tittle'>
          <h1>Quickly get funds to remodel or repair your home</h1>
        </div>
        <p>
          With a personal loan for home improvement, you can get the funds you
          need faster—without tapping into your equity or putting up collateral.
        </p>
        <p>
          Enjoy the convenience of a faster loan process. Access funds typically
          by the next day after approval. You won't need equity for this home
          improvement loan.
        </p>
        <p>Fast, easy application with low origination fees</p>
        <p>Fixed rates and monthly payments with terms up to 7 years</p>
        <p>Loan amounts ranging from $10,000 up to $75,000</p>
        <p>No collateral required and no home equity needed</p>
        <p>
          You can use a home improvement loan for any improvements to your
          primary or secondary residence. Most people use home improvement loans
          to fund projects like remodeling a kitchen, building a deck, replacing
          a roof or landscaping.
        </p>
        <div className='button'>
          <button onClick={() => setClose(false)}>close</button>
        </div>
      </div>
    </div>
  )
}

export default HomeLoan
