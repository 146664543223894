import React, { useState } from 'react'
import './auth.scss'
import Logo from '../../assets/images/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import { postNo } from '../../utilities/API/api'
import Loader from '../../components/loader/Loader'
function AdminLogin({ notify, Login }) {
  const [emailAddress, setEmailAddress] = useState('')
  const [password, setPassword] = useState('')
  const [formLoading, setFormLoading] = useState(false)
  const navigate = useNavigate()
  function submit() {
    if (emailAddress.length < 1) {
      notify('error', 'Email address is compulsory')
    } else {
      setFormLoading(true)
      const postData = {
        email: emailAddress,
        password: password,
      }
      postNo('admin/login', postData)
        .then((result) => {
          if (Object.keys(result).includes('error')) {
            notify('error', result.error)
          } else {
            notify('success', 'Welcome Admin')
            Login(result.token)
            setTimeout(() => {
              window.location.replace('/admin/users')
            }, 4000)
          }
          setFormLoading(false)
        })
        .catch((error) => {
          setFormLoading(false)
          notify('error', 'Server corrupted, contact your service provider.')
        })
    }
  }
  return (
    <div className='flex justify-center auth'>
      <div className='modal'>
        <div className='modal-wrap'>
          <div className='top'>
            <img src={Logo} alt='' onClick={() => navigate('/')} />
          </div>
          <div className='form'>
            <div className='form-cover'>
              <div className='form-card'>
                <p>Admin Login</p>
                <input
                  type='email'
                  name=''
                  id=''
                  onChange={(e) => setEmailAddress(e.target.value)}
                />
              </div>
              <div className='form-card'>
                <p>Password</p>
                <input
                  type='password'
                  name=''
                  id=''
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {formLoading ? (
                <Loader location='center' />
              ) : (
                <div className='form-card'>
                  <button onClick={submit} className='submit'>
                    Log In
                  </button>
                </div>
              )}
              <div className='flex justify-between'>
                <Link to={'/sign-up'}>
                  <p>Sign Up</p>
                </Link>
                <Link to={'/forget-password'}>
                  <p>Forgot password?</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminLogin
