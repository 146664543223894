import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { get } from '../../../../utilities/API/api'
import Loader from '../../../../components/loader/Loader'
import Pagination from '../../../../components/pagination/Pagination'
import { useGlobalContext } from '../../../../utilities/Context/Context'

function Users({ notify }) {
  const navigate = useNavigate()
  const { setVerifyData } = useGlobalContext()
  const token = sessionStorage.getItem('AdminToken')
  const [users, setUsers] = useState({})
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)

  //////////////////////////
  const FetchUsers = (page) => {
    get(`admin/users/fetch?pageSize=10&page=${page}`, token)
      .then((result) => {
        if (Object.keys(result).includes('error')) {
          notify('error', result.error)
          setLoading(false)
        } else {
          setUsers(result)
          setLoading(false)
        }
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    FetchUsers(1)
    // eslint-disable-next-line
  }, [])
  const paginationClick = (num) => {
    setLoading(true)
    FetchUsers(num)
  }
  function navigateToUser(id) {
    navigate(`/admin/user/${id}`)
  }

  return loading ? (
    <Loader location='center' />
  ) : (
    <div className='home_page'>
      <div className='home_tag'>
        <h1>Users</h1>
      </div>
      <div className='home_tables'>
        <div className='left' style={{ minHeight: '60vh' }}>
          <table>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Verified</th>
              </tr>
            </thead>
            <tbody>
              {users.users.map((item, i) => (
                <tr key={item._id}>
                  <td onClick={() => navigateToUser(item._id)}>{i + 1}</td>
                  <td onClick={() => navigateToUser(item._id)}>
                    {item.lastName} {item.firstName}
                  </td>
                  <td
                    className='count'
                    onClick={() => navigateToUser(item._id)}
                  >
                    {item.email}
                  </td>
                  <td
                    className='count'
                    onClick={() => navigateToUser(item._id)}
                  >
                    {item.phone}
                  </td>
                  <td>
                    {item.account_number ? (
                      <button>Verified</button>
                    ) : (
                      <button
                        className='danger'
                        onClick={() => setVerifyData(item._id)}
                      >
                        Not Verified
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        currentPage={currentPage}
        totalCount={users.totalPages}
        pageSize={10}
        onPageChange={(page) => {
          setCurrentPage(page)
          paginationClick(page)
        }}
      />
    </div>
  )
}

export default Users
