import React, { useState } from 'react'
import { ReactComponent as Logo } from '../../../../assets/images/favicon.svg'
import Loader from '../../../../components/loader/Loader'
import { put } from '../../../../utilities/API/api'
import { useGlobalContext } from '../../../../utilities/Context/Context'

function VerifyUser({ notify }) {
  const { verifyData, setVerifyData } = useGlobalContext()
  const Token = sessionStorage.getItem('AdminToken')
  const [accNumber, setAccNumber] = useState('')
  const [routingNumber, setRoutingNumber] = useState('')
  const [swiftCode, setSwiftCode] = useState('')
  const [formLoading, setFormLoading] = useState(false)
  function submit() {
    if (accNumber.length < 1) {
      notify('error', 'Account number is compulsory')
    } else if (routingNumber.length < 1) {
      notify('error', 'Routing number is compulsory')
    } else if (swiftCode.length < 1) {
      notify('error', 'Swift code is compulsory')
    } else {
      setFormLoading(true)
      const postData = {
        routing_number: routingNumber,
        account_number: accNumber,
        swift_code: swiftCode,
        banned: false,
        verified: true,
      }
      put(`admin/users/update?id=${verifyData}`, postData, Token)
        .then((result) => {
          if (Object.keys(result).includes('error')) {
            notify('error', result.error)
          } else {
            notify('success', 'User Verified.')
            setTimeout(() => {
              window.location.reload()
            }, 2000)
          }

          setFormLoading(false)
        })
        .catch((error) => {
          setFormLoading(false)
        })
    }
  }
  return (
    <div className='modal-type flex justify-center'>
      <div className='modal-body'>
        <div className='top '>
          <Logo />
          <h1>Verify User</h1>
        </div>
        <div className='form'>
          <div className='form-card'>
            <p>Account number</p>
            <input
              type='text'
              name=''
              id=''
              onChange={(e) => setAccNumber(e.target.value)}
            />
          </div>
          <div className='form-card'>
            <p>Routing number</p>
            <input
              type='text'
              name=''
              id=''
              onChange={(e) => setRoutingNumber(e.target.value)}
            />
          </div>
          <div className='form-card'>
            <p>Swift Code</p>
            <input
              type='text'
              name=''
              id=''
              onChange={(e) => setSwiftCode(e.target.value)}
            />
          </div>
          {formLoading ? (
            <Loader location='center' />
          ) : (
            <div className='form-card button'>
              <button onClick={() => setVerifyData(null)} className='danger'>
                Cancel
              </button>
              <button onClick={submit}>Verify</button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default VerifyUser
