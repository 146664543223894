const SmallCards = [
  {
    icon: 'fam-checking',
    details: 'Small business checking',
  },
  {
    icon: 'fam-borrow',
    details: 'Apply for a loan',
  },
  {
    icon: 'fam-credit-cards',
    details: 'Find business credit cards',
  },
  {
    icon: 'lib-0394-business-handshake-deal-contract-sign',
    details: 'Merchant Services',
  },
  {
    icon: 'fam-insurance',
    details: 'ACH Services',
  },
  {
    icon: 'lib-0666-award-achievement-prize-medal',
    details: 'Expertise',
  },
]

export default SmallCards
