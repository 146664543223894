import React, { useState } from 'react'
import NavBar from '../../components/navBar/NavBar'
import Icons from '../../assets/images/icons.svg'
import SmallCards from '../../assets/data/SmallCards'
import personalImage from '../../assets/images/smallImage.jpeg'
import Phone from '../../assets/images/Phone 2.png'
import Footer from '../../components/footer/Footer'
import Forever from './Forever'
import Anniversary from './Anniversary'
import SmallInsights from '../../assets/data/SmallInsight'
import Insights from './Insights'
import Disclosures from './Disclosures'
import OpenAccount from './OpenAccount'
import { useNavigate } from 'react-router-dom'
import BusinessAccount from '../../components/footer/popover/BusinessAccount'
import BusinessSavings from '../../components/footer/popover/BusinessSavings'

function SmallBusiness() {
  const navigate = useNavigate()
  const [showBusiness, setShowBusiness] = useState(false)
  const [businessSavings, setBusinessSavings] = useState(false)
  return (
    <div className='home-page'>
      {showBusiness && <BusinessAccount setClose={setShowBusiness} />}
      {businessSavings && <BusinessSavings setClose={setBusinessSavings} />}

      <NavBar />
      <div className='hero small justify-center'>
        <div className='hero-cover'>
          <div className='image-container'>
            <img src={personalImage} alt='' />
          </div>
          <div className='text-container'>
            <div className='fade-der'></div>
            <h1>Manage deposits for your ever-evolving business</h1>
            <div className='flex flex-wrap'>
              <button
                className='button-bold'
                onClick={() => navigate('/sign-up')}
              >
                Open now
              </button>
            </div>
          </div>
          <div className='flex justify-between flex-wrap cards-wrapper'>
            {SmallCards.map((item) => (
              <div
                className='card'
                key={item.icon}
                onClick={() => navigate('/login')}
              >
                <svg>
                  <use xlinkHref={`${Icons}#${item.icon}`} />
                </svg>
                <h3>{item.details}</h3>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='ai-banner justify-center'>
        <div className='ai-cover flex justify-between flex-wrap'>
          <h1>Discover how generative AI impacts our lives</h1>
          <button
            className='button-outline '
            onClick={() => navigate('/sign-up')}
          >
            Sign up
          </button>
        </div>
      </div>
      <div className='loaner justify-center'>
        <div className='cards-cover flex justify-between flex-wrap'>
          <div className='card'>
            <div className='rest flex'>
              <svg>
                <use xlinkHref={`${Icons}#fam-retirement`} />
              </svg>
              <p className='tag'>Checking</p>
              <h2>Business Checking Accounts</h2>
              <p className='quick'>
                Choose from a variety of business checking accounts to fit your
                needs as your business grows.
              </p>
              <button
                className='button-text white'
                onClick={() => setShowBusiness(true)}
              >
                Learn More
              </button>
            </div>
          </div>
          <div className='card green'>
            <div className='rest flex'>
              <svg>
                <use xlinkHref={`${Icons}#fam-borrow`} />
              </svg>
              <p className='tag'>Small business</p>
              <h2>Business Savings Accounts & CDs</h2>
              <p className='quick'>
                Put your hard-earned extra cash flow to work and help meet your
                financial goals with a small business savings account from Mint
                Capital.
              </p>
              <button
                className='button-text white'
                onClick={() => setBusinessSavings(true)}
              >
                Learn More
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='track justify-center'>
        <div className='track-cover flex justify-center flex-wrap'>
          <div className='left'>
            <img src={Phone} alt='' />
          </div>
          <div className='right'>
            <h3>DIGITAL BANKING FOR BUSINESS</h3>
            <div>
              <h2>Manage your accounts from anywhere</h2>
            </div>
            <div>
              <h2>
                Transfer between accounts or send money using ACH and wires
              </h2>
            </div>
            <div>
              <h2>Receive account and security alerts</h2>
            </div>
            <button className='button-text' onClick={() => navigate('/login')}>
              Learn More
            </button>
          </div>
        </div>
      </div>
      <Forever />
      <Anniversary />
      <OpenAccount />
      <Insights content='Grow your business' data={SmallInsights} />
      <Disclosures />
      <Footer />
    </div>
  )
}

export default SmallBusiness
