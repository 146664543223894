import React from 'react'

function Invest({ setClose }) {
  return (
    <div className='popup'>
      <div className='pop-cover'>
        <div className='tittle'>
          <h1>Invest on your terms</h1>
        </div>
        <p>
          However you like to manage your investments, we have the resources to
          help you be smarter with money—whether you prefer self-directed access
          or want full-service investment planning services.
        </p>
        <p>
          We'll pull together your full financial picture, analyze past market
          and portfolio performance and provide you with industry news to help
          guide future investment decisions. With the right information, you can
          find a balance between risk and return that works for you and stay on
          track.
        </p>
        <p>
          Through the combined efforts of our Investment Advisory Services and
          Mint capital alliance Investor Services teams—a broker/dealer and
          Registered Investment Advisor—you get the advice and assistance of
          experienced investment specialists.
        </p>
        <div className='button'>
          <button onClick={() => setClose(false)}>close</button>
        </div>
      </div>
    </div>
  )
}

export default Invest
