import React, { useState } from 'react'
import AnniversaryImg from '../../assets/images/anivesary.png'
import Forever from '../../components/footer/popover/Forever'

function Anniversary() {
  const [show, setShow] = useState(false)
  return (
    <div className='anniversary justify-center'>
      {show && <Forever setClose={setShow} />}
      <div className='ani-cover flex justify-between flex-wrap'>
        <div className='left'>
          <img src={AnniversaryImg} alt='' />
        </div>
        <div className='right'>
          <h1>Forever starts with the first 125 years</h1>
          <button
            className='button-outline button-green '
            onClick={() => setShow(true)}
          >
            Learn More
          </button>
        </div>
      </div>
    </div>
  )
}

export default Anniversary
