import React from 'react'
import './Loader.scss'
import loader from '../../assets/images/loder.gif'

function Loader({ location }) {
  return (
    <div className={`${location} loader`}>
      <img src={loader} alt='' />
    </div>
  )
}

export default Loader
