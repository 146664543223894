import React from 'react'

function Email({ setClose }) {
  return (
    <div className='popup'>
      <div className='pop-cover'>
        <div className='tittle'>
          <h1>Contact us</h1>
        </div>
        <p>Email: support@mintcapitalallinace.com</p>
        <p>Tel: +14436988399</p>
        <div className='button'>
          <button onClick={() => setClose(false)}>close</button>
        </div>
      </div>
    </div>
  )
}

export default Email
