import React, { useState, useEffect } from 'react'
import { get, put } from '../../../../utilities/API/api'
import { useParams } from 'react-router-dom'
import Loader from '../../../../components/loader/Loader'

function Transaction({ notify }) {
  const [transactionData, setTransactionData] = useState({})
  const Token = sessionStorage.getItem('AdminToken')
  const [loading, setLoading] = useState(true)
  const { id } = useParams()
  const [formLoading, setFormLoading] = useState(false)
  useEffect(() => {
    get(`admin/transfer/single/${id}`, Token).then((result) => {
      setTransactionData(result.transaction)
      setLoading(false)
    })
    // eslint-disable-next-line
  }, [])

  function decline() {
    setFormLoading(true)
    put(`users/transfer/decline?id=${id}`, null, Token)
      .then((result) => {
        if (Object.keys(result).includes('error')) {
          notify('error', result.error)
        } else {
          notify('success', 'Transfer Declined.')
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        }
        setFormLoading(false)
      })
      .catch((error) => {
        setFormLoading(false)
      })
  }
  function approve() {
    setFormLoading(true)
    put(`users/transfer/approve?id=${id}`, null, Token)
      .then((result) => {
        if (Object.keys(result).includes('error')) {
          notify('error', result.error)
        } else {
          notify('success', 'Transfer approved.')
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        }
        setFormLoading(false)
      })
      .catch((error) => {
        setFormLoading(false)
      })
  }

  return loading ? (
    <Loader location='center' />
  ) : (
    <div className='admin-user'>
      <div className='details'>
        <div className='det-top'>
          <h2>Transaction Details</h2>
        </div>
        <div className='flex justify-between flex-wrap'>
          <div className='form-card'>
            <p>Transfer Status</p>
            <div className='input'>
              <h4>{transactionData.state}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Amount</p>
            <div className='input'>
              <h4>${transactionData.amount.toLocaleString()}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Transfer type</p>
            <div className='input'>
              <h4>{transactionData.debit_type}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Transaction Date</p>
            <div className='input'>
              <h4>
                {new Date(transactionData.createdAt).toDateString()},{' '}
                {new Date(transactionData.createdAt).toLocaleTimeString()}
              </h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Recipient name</p>
            <div className='input'>
              <h4>{transactionData.recipeint_name}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Recipient bank name</p>
            <div className='input'>
              <h4>{transactionData.recipeint_bank_name}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Recipient Account number</p>
            <div className='input'>
              <h4>{transactionData.account_number}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Recipient routing number</p>
            <div className='input'>
              <h4>{transactionData.routing_number}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Account type</p>
            <div className='input'>
              <h4>{transactionData.account_type}</h4>
            </div>
          </div>

          <div className='form-card'>
            <p>Description</p>
            <div className='input'>
              <h4>{transactionData.description} </h4>
            </div>
          </div>
          {transactionData.debit_type !== 'local' && (
            <>
              <div className='form-card'>
                <p>Recipient address</p>
                <div className='input'>
                  <h4>{transactionData.recipeint_address} </h4>
                </div>
              </div>
              <div className='form-card'>
                <p>Recipient bank address</p>
                <div className='input'>
                  <h4>{transactionData.recipeint_bank_address} </h4>
                </div>
              </div>
              <div className='form-card'>
                <p>Swift / bic code</p>
                <div className='input'>
                  <h4>{transactionData.swift_bic_code} </h4>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {transactionData.state === 'pending' && (
        <div className='buttons-head dif'>
          {formLoading ? (
            <Loader location='right' />
          ) : (
            <>
              {' '}
              <button onClick={() => approve()}>Approve transfer</button>
              <button onClick={() => decline()} className='danger'>
                Decline Transfer
              </button>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default Transaction
