import Image4 from '../../assets/images/image4.jpeg'
import Image5 from '../../assets/images/image5.jpeg'
import Image6 from '../../assets/images/image6.jpeg'
const SmallInsights = [
  {
    id: 1,
    image: Image4,
    header: 'Cash Management',
    details: 'Managing Cash Flow Is a Critical',
  },
  {
    id: 2,
    image: Image5,
    header: 'Banking',
    details: 'Tips for Choosing a Business Banking',
  },
  {
    id: 3,
    image: Image6,
    header: 'Finance',
    details: 'Tips for Managing Business',
  },
]

export default SmallInsights
