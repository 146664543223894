import React, { useState } from 'react'
import { ReactComponent as Logo } from '../../../../assets/images/favicon.svg'
import Loader from '../../../../components/loader/Loader'
import { post } from '../../../../utilities/API/api'
import { useGlobalContext } from '../../../../utilities/Context/Context'

function ProfilePicture({ notify }) {
  const { setShowProfileImage } = useGlobalContext()
  const Token = sessionStorage.getItem('UserToken')
  const [formLoading, setFormLoading] = useState(false)
  const [frontId, setFrontId] = useState(null)

  const handleUpload = async (id) => {
    try {
      // Create FormData and append the selected file
      const formData = new FormData()
      formData.append('image', id)

      // Make a POST request to your Node.js server
      const response = await fetch(
        'https://fcd-be-e3a65f61233d.herokuapp.com/upload',
        {
          method: 'POST',
          body: formData,
        }
      )

      // Parse the JSON response
      const data = await response.json()

      // Set the imageUrl state with the Cloudinary URL
      return data.imageUrl
    } catch (error) {
      console.error('Error uploading image:', error)
    }
  }
  const submit = async () => {
    if (frontId === null) {
      notify('error', 'Image is compulsory')
    } else {
      setFormLoading(true)
      const front = await handleUpload(frontId)
      const postData = {
        profile_image: front,
      }
      post(`users/profile-image/set`, postData, Token)
        .then((result) => {
          if (Object.keys(result).includes('error')) {
            notify('error', result.error)
          } else {
            notify('success', 'Profile image uploaded successfully.')
            setTimeout(() => {
              window.location.reload()
            }, 2000)
          }

          setFormLoading(false)
        })
        .catch((error) => {
          setFormLoading(false)
        })
    }
  }
  return (
    <div className='modal-type flex justify-center'>
      <div className='modal-body'>
        <div className='top '>
          <Logo />
          <h1>Profile Image upload.</h1>
          <p style={{ textAlign: 'center' }}>Upload profile image.</p>
        </div>
        <div className='form'>
          <div className='form-card'>
            <p>Profile Image</p>
            <input
              type='file'
              name=''
              id=''
              onChange={(e) => setFrontId(e.target.files[0])}
            />
          </div>
          {formLoading ? (
            <Loader location='center' />
          ) : (
            <div className='form-card button'>
              <button
                onClick={() => setShowProfileImage(false)}
                className='danger'
              >
                Cancel
              </button>
              <button onClick={submit}>Upload</button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProfilePicture
