import React from 'react'

function Accessibility({ setClose }) {
  return (
    <div className='popup'>
      <div className='pop-cover'>
        <div className='tittle'>
          <h1>Website Accessibility Statement</h1>
        </div>
        <p>
          At Mint capital alliance, we take our responsibility towards
          accessibility very seriously. We are continuously working to enhance
          our website to increase accessibility for all of our customers,
          including those with disabilities.
        </p>
        <p>
          We have actively taken steps and devoted resources to increase the
          accessibility of our website to adhere to standards recommended by the
          World Wide Web Consortium's (W3C's) Web Content Accessibility
          Guidelines 2.0 AA (WCAG 2.0 AA).
        </p>
        <p>
          If you are leveraging a screen reader or other assistive technology,
          we recommend that you use a current version of one of the following
          browsers for the best banking experience on here.
        </p>
        <div className='button'>
          <button onClick={() => setClose(false)}>close</button>
        </div>
      </div>
    </div>
  )
}

export default Accessibility
