import React from 'react'

function Privacy({ setClose }) {
  return (
    <div className='popup'>
      <div className='pop-cover'>
        <div className='tittle'>
          <h1>We take protecting your personal information seriously</h1>
        </div>
        <p>
          The types of Personal Information we collect depends on your
          interaction with us, including the types of products or services you
          applied for or use. We, or entities that we contract with to provide
          services to support our business and delivery of our products and
          services ("Service Providers"), may collect Personal Information:
          Directly from you, such as when you apply for or obtain one of our
          products or services, or if you apply for a job with us; From
          financial and non-financial companies related by common ownership or
          control (our "Affiliates"), based on your relationship with them and
          as permitted by law; and/or From other entities that we work with who
          are not Service Providers ("Third Parties"), such as credit bureaus or
          marketing partners.
        </p>
        <p>
          We (or our Service Providers) may also collect Online Information,
          such as IP address, browser or device information, or other
          information about you directly or indirectly through interactions with
          our Sites, applications, or ads, such as: The internet address
          (referral site) which brought you to the Site; The types of devices
          you use to visit our Sites and interact with us such as device,
          operating system, web browser, and the IP address; Your device's
          browsing history on our Sites and the time and duration of your visit;
          Information about the ads or content from us (or our Service
          Providers) that you view, access or click on; Information that is
          collected through Cookies, Web Beacons, and/or mobile ad identifiers
          as described below; The location of the device you use to visit our
          Sites (subject to applicable law or with your consent); or Your
          contact lists, photos, or access to your camera, when you request
          certain services that require such access, and only with your consent.
          For example, to initiate a money transfer or access the remote deposit
          capture features of our Sites or mobile applications. Though Online
          Information may not, alone, reveal your specific identity, some of
          this information may be used or associated with Personal Information,
          or may itself be considered Personal Information.
        </p>
        <div className='button'>
          <button onClick={() => setClose(false)}>close</button>
        </div>
      </div>
    </div>
  )
}

export default Privacy
