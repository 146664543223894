import React from 'react'
import './Sidebar.scss'
import { useNavigate } from 'react-router-dom'
import { useGlobalContext } from '../../../../utilities/Context/Context'
import { Icon } from '@iconify/react'
import Logo from '../../../../assets/images/logo-white.png'

function Sidebar({ page, notify }) {
  const navigate = useNavigate()
  const {
    toggleSidebar,
    handleSidebarToggle,
    setInitiateTransfer,
    userProfile,
  } = useGlobalContext()
  return (
    <div className={toggleSidebar ? 'user-sidebar' : 'user-sidebar active'}>
      <div className='columns'>
        <div className='log'>
          <img src={Logo} alt='' />
        </div>
        <div
          className={page === 'home' ? 'row active' : 'row'}
          onClick={() => {
            navigate('/user/home')
            handleSidebarToggle()
          }}
        >
          <div className='icon'>
            <Icon icon='line-md:home-twotone' />
          </div>
          <p>Home</p>
        </div>
        <div
          className={page === 'send' ? 'row active' : 'row'}
          onClick={() => {
            setInitiateTransfer(true)
            handleSidebarToggle()
          }}
        >
          <div className='icon'>
            <Icon icon='entypo:paper-plane' />
          </div>
          <p>Send Money</p>
        </div>
        <div
          className={page === 'cards' ? 'row active' : 'row'}
          onClick={() => {
            navigate('/user/cards')
            handleSidebarToggle()
          }}
        >
          <div className='icon'>
            <Icon icon='ei:credit-card' />
          </div>
          <p>Cards</p>
        </div>
        <div
          className={page === 'transactions' ? 'row active' : 'row'}
          onClick={() => {
            navigate('/user/transactions')
            handleSidebarToggle()
          }}
        >
          <div className='icon'>
            <Icon icon='fa6-solid:money-bill-transfer' />
          </div>
          <p>Transactions</p>
        </div>
        <div
          className={page === 'loan' ? 'row active' : 'row'}
          onClick={() => {
            navigate('/user/loan')
            handleSidebarToggle()
          }}
        >
          <div className='icon'>
            <Icon icon='solar:hand-money-bold' />
          </div>
          <p>Loan</p>
        </div>
        <div
          className={page === 'savings' ? 'row active' : 'row'}
          onClick={() => {
            navigate('/user/savings')
            handleSidebarToggle()
          }}
        >
          <div className='icon'>
            <Icon icon='vaadin:piggy-bank-coin' />
          </div>
          <p>Savings</p>
        </div>
        <div
          className={page === 'profile' ? 'row active' : 'row'}
          onClick={() => {
            navigate('/user/profile')
            handleSidebarToggle()
          }}
        >
          <div className='icon'>
            <Icon icon='line-md:account' />
          </div>
          <p>Profile</p>
        </div>
        <div
          className={page === 'support' ? 'row active' : 'row'}
          onClick={() => {
            navigate('/user/support')
            handleSidebarToggle()
          }}
        >
          <div className='icon'>
            <Icon icon='fluent:person-support-16-filled' />
          </div>
          <p>Support</p>
        </div>
        {userProfile.linked && (
          <div
            className={userProfile.linked ? 'row active link' : 'row'}
            onClick={() => {
              notify('warn', 'Account already linked.')
            }}
          >
            <div className='icon'>
              <Icon icon='ph:link-bold' />
            </div>
            <p>Linked</p>
          </div>
        )}
        <div
          className='row'
          onClick={() => {
            sessionStorage.clear()
            window.location.replace('/login')
          }}
        >
          <div className='icon'>
            <Icon icon='line-md:log-out' />
          </div>
          <p>Log out</p>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
