import React, { useState } from 'react'
import NavBar from '../../components/navBar/NavBar'
import './HomePage.scss'
import personalImage from '../../assets/images/wealthSmall.jpeg'
import WealthCards from '../../assets/data/WealthCards'
import Icons from '../../assets/images/icons.svg'
import Forever from './Forever'
import Anniversary from './Anniversary'
import OpenAccount from './OpenAccount'
import Insights from './Insights'
import Disclosures from './Disclosures'
import Footer from '../../components/footer/Footer'
import SmallInsights from '../../assets/data/SmallInsight'
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom'
import Invest from '../../components/footer/popover/Invest'
import Cancer from '../../components/footer/popover/Cancer'

function Wealth() {
  const navigate = useNavigate()
  const [invest, setInvest] = useState(false)
  const [cancer, setCancer] = useState(false)
  return (
    <div className='home-page'>
      {invest && <Invest setClose={setInvest} />}
      {cancer && <Cancer setClose={setCancer} />}
      <NavBar />
      <div className='hero wealth justify-center'>
        <div className='hero-cover'>
          <div className='image-container'>
            <img src={personalImage} alt='' />
          </div>
          <div className='text-container'>
            <div className='fade-der'></div>
            <h1>Navigate today's financial landscape</h1>
            <div className='flex flex-wrap'>
              <button
                className='button-bold'
                onClick={() => navigate('/login')}
              >
                Get Our Market Outlook
              </button>
            </div>
          </div>
          <div className='flex justify-between flex-wrap cards-wrapper'>
            {WealthCards.map((item) => (
              <div
                className='card'
                key={item.icon}
                onClick={() => navigate('/login')}
              >
                <Icon icon={item.icon} />
                <h3>{item.details}</h3>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='ai-banner justify-center'>
        <div className='ai-cover flex justify-between flex-wrap'>
          <h1>Discover how generative AI impacts our lives</h1>
          <button
            className='button-outline '
            onClick={() => navigate('/sign-up')}
          >
            Sign up
          </button>
        </div>
      </div>
      <div className='loaner justify-center'>
        <div className='cards-cover flex justify-between flex-wrap'>
          <div className='card dark'>
            <div className='rest flex'>
              <svg>
                <use xlinkHref={`${Icons}#fam-philosophy`} />
              </svg>
              <p className='tag'>INVESTMENT MANAGEMENT</p>
              <h2>Build an investment plan that lasts</h2>
              <p className='quick'>
                We keep an eye on your long-term goals and avoid trends that
                come and go.
              </p>
              <button
                className='button-text white'
                onClick={() => setInvest(true)}
              >
                Learn More
              </button>
            </div>
          </div>
          <div className='card dark'>
            <div className='rest flex'>
              <svg>
                <use xlinkHref={`${Icons}#lib-1360_sharing_love`} />
              </svg>
              <p className='tag'>MERCHANT SERVICES</p>
              <h2>TEEN CANCER AMERICA</h2>
              <p className='quick'>Together we can transform lives.</p>
              <button
                className='button-text white'
                onClick={() => setCancer(true)}
              >
                Learn More
              </button>
            </div>
          </div>
        </div>
      </div>
      <Forever />
      <Anniversary />
      <OpenAccount />
      <Insights content='Grow your business' data={SmallInsights} />
      <Disclosures />
      <Footer />
    </div>
  )
}

export default Wealth
