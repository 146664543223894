import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export default function App() {
  const [remaining, setRemaining] = useState(0)
  const navigate = useNavigate()

  const onIdle = () => {
    toast.warn('Session Timed-out', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    })
    navigate('/')
    sessionStorage.clear()
  }
  //After 4hrs of inactivity
  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 300000,
    throttle: 500,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })
  return <></>
}
