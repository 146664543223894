import React, { useState } from 'react'
import { ReactComponent as Logo } from '../../../../assets/images/favicon.svg'
import Loader from '../../../../components/loader/Loader'
import { post } from '../../../../utilities/API/api'
import { useGlobalContext } from '../../../../utilities/Context/Context'

function SavingsModal({ notify }) {
  const { setShowSavings } = useGlobalContext()
  const Token = sessionStorage.getItem('UserToken')
  const [amount, setAmount] = useState(0)
  const [duration, setDuration] = useState('')
  const [formLoading, setFormLoading] = useState(false)
  const [pin, setPin] = useState('')
  function submit() {
    if (amount < 1) {
      notify('error', 'Amount is compulsory')
    } else if (duration.length < 1) {
      notify('error', 'Duration is compulsory')
    } else if (pin.length !== 4) {
      notify('error', 'Transaction Pin is compulsory')
    } else {
      setFormLoading(true)
      const postData = {
        amount: parseFloat(amount),
        savingsDurationDays: duration,
        savingsDailyAmount: calculateDailyReturnsForTotalInterest(
          amount,
          duration
        ),
      }
      post(`users/transaction-pin/verify`, { transactionPin: pin }, Token)
        .then((result) => {
          if (Object.keys(result).includes('error')) {
            notify('error', result.error)
            setFormLoading(false)
          } else {
            post(`users/savings`, postData, Token)
              .then((result) => {
                if (Object.keys(result).includes('error')) {
                  notify('error', result.error)
                  setFormLoading(false)
                } else {
                  notify('success', 'Saving plan created successful.')
                  setFormLoading(false)
                  setTimeout(() => {
                    window.location.reload()
                  }, 2000)
                }
              })
              .catch((error) => {
                setFormLoading(false)
              })
          }
        })
        .catch((error) => {
          setFormLoading(false)
        })
    }
  }
  function calculateDailyReturnsForTotalInterest(
    investmentAmount,
    numberOfDays
  ) {
    // Calculate total returns
    // Define the annual gain rate
    const annualGainRate = 0.14 // 14% per annum gain

    // Calculate the daily gain rate
    const dailyGainRate = annualGainRate / 365

    // Calculate the total amount after the given number of days
    const totalAmount =
      investmentAmount * Math.pow(1 + dailyGainRate, numberOfDays)
    const dailyReturn = totalAmount / numberOfDays

    return dailyReturn.toFixed(2)
  }
  return (
    <div className='modal-type flex justify-center'>
      <div className='modal-body'>
        <div className='top '>
          <Logo />
          <h1>Create a savings plan</h1>
          <p>
            Invest wisely and watch your savings grow. With our Savings Plan,
            you'll receive daily returns on your investment until the agreed
            duration is completed. Please note that the daily returns are
            calculated based on the terms you've selected and we give an 14% per
            annum interest.
          </p>
        </div>
        <div className='form'>
          <div className='form-card'>
            <p>Amount</p>
            <input
              type='number'
              name=''
              id=''
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
          <div className='form-card'>
            <p>Duration</p>
            <select name='' id='' onChange={(e) => setDuration(e.target.value)}>
              <option value=''>- select - </option>
              <option value={30}>30 days</option>
              <option value={60}>60 days</option>
              <option value={90}>90 days</option>
              <option value={180}>180 days</option>
              <option value={270}>270 days</option>
              <option value={365}>365 days</option>
            </select>
          </div>
          <div className='form-card'>
            <p>Transaction Pin</p>
            <input
              type='password'
              name='pin'
              id='pin'
              value={pin}
              maxLength={4}
              pattern='\d*'
              onChange={(e) => {
                if (/^\d*$/.test(e.target.value)) {
                  // Limit the input to 4 digits
                  setPin(e.target.value.slice(0, 4))
                }
              }}
            />
          </div>
          {formLoading ? (
            <Loader location='center' />
          ) : (
            <div className='form-card button'>
              <button onClick={() => setShowSavings(false)} className='danger'>
                Cancel
              </button>
              <button onClick={submit}>Create saving plan</button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SavingsModal
