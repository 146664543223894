import React from 'react'

function Community({ setClose }) {
  return (
    <div className='popup'>
      <div className='pop-cover'>
        <div className='tittle'>
          <h1>Supporting Our Communities</h1>
        </div>
        <p>
          In our line of work, people come first. That’s why we’re proud to lend
          our support to causes that help people in need. As a company, we work
          with organizations in the areas we serve to help them make our
          communities stronger. Our employees also donate hundreds of volunteer
          hours every year to causes close to their hearts.
        </p>
        <p>
          Mint capital alliance is proud to announce a $16 billion, 5-year
          Community Benefits Plan (CBP) that builds on our ongoing work to
          reinvest in low- and moderate-income (LMI) communities. This plan will
          help increase our investment in the cities and towns we serve and help
          grow vibrant and diverse communities and businesses.
        </p>
        <p>
          We’re inspired by women leaders that embody boldness, compassion and
          growth. The dynamics of finance, wealth, and work are moving in new
          upward directions. Let's reach those new heights together. We've
          collected helpful resources and insights that support business growth
          for women leading the effort across the US.
        </p>
        <p>
          Since 2015, we've helped California-based national nonprofit Teen
          Cancer America—founded by Roger Daltrey and Pete Townsend of the rock
          band, The Who—raise awareness and funding for TCA hospitals in our
          markets. Our work is helping improve the experience and outcomes of
          teens and young adults with cancer by creating facilities and programs
          especially for them. We’re proud that we’ve helped establish TCA
          partnerships with major medical centers to help transform the lives of
          young people with cancer. We hope you’ll join us in this endeavor.
        </p>
        <div className='button'>
          <button onClick={() => setClose(false)}>close</button>
        </div>
      </div>
    </div>
  )
}

export default Community
