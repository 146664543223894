import React, { useState } from 'react'
import Icons from '../../assets/images/icons.svg'
import { useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'

function OpenAccount() {
  const navigate = useNavigate()
  const [showMail, setShowMail] = useState(false)
  const [showTel, setShowTel] = useState(false)
  return (
    <div className='open justify-center'>
      <div className='open-cover'>
        <h4>BANK ON CUSTOMER SERVICE</h4>
        <h2>Open an Account Today</h2>
        <div className=' justify-center cards flex-wrap'>
          <div className='card' onClick={() => navigate('/sign-up')}>
            <svg>
              <use xlinkHref={`${Icons}#fam-checking`} />
            </svg>
            <h3 className='flex justify-center'>
              Open an Account <Icon icon='ep:arrow-right-bold' />
            </h3>
            <p>See all we have to offer.</p>
          </div>
          <div className='card'>
            <svg>
              <use xlinkHref={`${Icons}#cta-email`} />
            </svg>
            <h3 className='flex justify-center'>
              Email Us{' '}
              {showMail ? (
                <Icon
                  icon='ep:arrow-up-bold'
                  onClick={() => setShowMail(!showMail)}
                />
              ) : (
                <Icon
                  icon='ep:arrow-down-bold'
                  onClick={() => setShowMail(!showMail)}
                />
              )}
            </h3>
            <p>
              {showMail
                ? 'support@mintcapitalallinace.com'
                : 'Meet our associates.'}
            </p>
          </div>
          <div className='card'>
            <svg>
              <use xlinkHref={`${Icons}#cta-call-us`} />
            </svg>
            <h3 className='flex justify-center'>
              Call Us{' '}
              {showTel ? (
                <Icon
                  icon='ep:arrow-up-bold'
                  onClick={() => setShowTel(!showTel)}
                />
              ) : (
                <Icon
                  icon='ep:arrow-down-bold'
                  onClick={() => setShowTel(!showTel)}
                />
              )}
            </h3>
            <p></p>
            <p>{showTel ? '+14436988399' : `Reach out and we'll help.`}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OpenAccount
