import Image1 from '../../assets/images/image1.jpeg'
import Image2 from '../../assets/images/image2.jpeg'
import Image3 from '../../assets/images/image3.jpeg'
const PersonalInsights = [
  {
    id: 1,
    image: Image1,
    header: 'SAVING',
    details:
      ' Are Deposits a Money Maker Again? The Resurgence of High-Yield Accounts ',
  },
  {
    id: 2,
    image: Image2,
    header: 'HOME',
    details: 'How Do Home Construction Loans work',
  },
  {
    id: 3,
    image: Image3,
    header: 'RETIREMENT',
    details: 'Ages and Stages of Saving for Retirement',
  },
]

export default PersonalInsights
