import React, { useState } from 'react'
import { ReactComponent as Logo } from '../../../../assets/images/favicon.svg'
import Loader from '../../../../components/loader/Loader'
import { post } from '../../../../utilities/API/api'

function SetPin({ notify }) {
  const Token = sessionStorage.getItem('UserToken')
  const [formLoading, setFormLoading] = useState(false)
  //////////////
  const [pin, setPin] = useState('')
  const [confirmPin, setConfirmPin] = useState('')
  const [question1, setQuestion1] = useState('')
  const [question2, setQuestion2] = useState('')
  const [question3, setQuestion3] = useState('')

  function submit() {
    if (pin.length !== 4) {
      notify('error', 'Transaction pin must be 4 digits')
    } else if (pin !== confirmPin) {
      notify('error', 'Pins do not Match.')
    } else if (question1.length < 1) {
      notify('error', 'Mother maiden name is compulsory')
    } else if (question2.length < 1) {
      notify('error', 'city of birth is compulsory')
    } else if (question3.length < 1) {
      notify('error', 'Favorite Childhood Cartoon Character is compulsory')
    } else {
      setFormLoading(true)
      const postData = [
        {
          question: `Mother's Maiden Name?`,
          answer: question1,
        },
        {
          question: `City of Birth?`,
          answer: question2,
        },
        {
          question: `Favorite Childhood Cartoon Character?`,
          answer: question3,
        },
      ]
      post(`users/transaction-pin/set`, { transactionPin: pin }, Token)
        .then((result) => {
          if (Object.keys(result).includes('error')) {
            notify('error', result.error)
          } else {
            post(`users/security-question/set`, { questions: postData }, Token)
              .then((result) => {
                if (Object.keys(result).includes('error')) {
                  notify('error', result.error)
                } else {
                  notify('success', 'Security details set.')
                  setTimeout(() => {
                    window.location.reload()
                  }, 2000)
                }
                setFormLoading(false)
              })
              .catch((error) => {
                setFormLoading(false)
              })
          }
        })
        .catch((error) => {
          setFormLoading(false)
        })
    }
  }
  return (
    <div className='modal-type flex justify-center'>
      <div className='modal-body'>
        <div className='top '>
          <Logo />
          <h1>Set transaction pin and Security questions.</h1>
        </div>
        <div className='form'>
          <div className='form-card'>
            <p>Pin</p>
            <input
              type='password'
              name='pin'
              id='pin'
              value={pin}
              maxLength={4}
              pattern='\d*'
              onChange={(e) => {
                if (/^\d*$/.test(e.target.value)) {
                  // Limit the input to 4 digits
                  setPin(e.target.value.slice(0, 4))
                }
              }}
            />
          </div>
          <div className='form-card'>
            <p>Confirm Pin</p>
            <input
              type='password'
              name='pin'
              id='pin'
              value={confirmPin}
              maxLength={4}
              pattern='\d*'
              onChange={(e) => {
                if (/^\d*$/.test(e.target.value)) {
                  // Limit the input to 4 digits
                  setConfirmPin(e.target.value.slice(0, 4))
                }
              }}
            />
          </div>
          <div className='form-card'>
            <p>Mother's Maiden Name? </p>
            <input
              type='text'
              name=''
              id=''
              onChange={(e) => setQuestion1(e.target.value)}
            />
          </div>
          <div className='form-card'>
            <p>City of Birth?</p>
            <input
              type='text'
              name=''
              id=''
              onChange={(e) => setQuestion2(e.target.value)}
            />
          </div>
          <div className='form-card'>
            <p>Favorite Childhood Cartoon Character?</p>
            <input
              type='text'
              name=''
              id=''
              onChange={(e) => setQuestion3(e.target.value)}
            />
          </div>
          {formLoading ? (
            <Loader location='center' />
          ) : (
            <div className='form-card button'>
              <button onClick={submit}>Set Details.</button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SetPin
