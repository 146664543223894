import React, { useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import HomePage from './pages/homePage/HomePage'
import SmallBusiness from './pages/homePage/SmallBusiness'
import Commercial from './pages/homePage/Commercial'
import Wealth from './pages/homePage/Wealth'
import Login from './pages/auth/Login'
import SignUp from './pages/auth/SignUp'
import About from './pages/homePage/About'
import ScrollToTop from './components/scroll/Scroll'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AdminLogin from './pages/auth/AdminLogin'
import AdminDashboard from './pages/admin/AdminDashboard'
import AdminProtected from './routes/AdminProtected'
import ResetPassword from './pages/auth/ResetPassword'
import ForgetPassword from './pages/auth/ForgetPassword'
import UserProtected from './routes/UserProtected'
import UserDashboard from './pages/user/UserDashboard'

function App() {
  const notify = (type, message) => {
    if (type === 'info') {
      toast.info(message)
    }
    if (type === 'success') {
      toast.success(message)
    }
    if (type === 'warn') {
      toast.warn(message)
    }
    if (type === 'error') {
      toast.error(message)
    }
  }
  const [loggedIn, setLoggedIn] = useState(false)
  const [adminIn, setAdminIn] = useState(false)
  const logAdminIn = (admin) => {
    sessionStorage.setItem('AdminToken', admin)
    setAdminIn(true)
  }
  const logUserIn = (admin) => {
    sessionStorage.setItem('UserToken', admin)
    setLoggedIn(true)
  }
  return (
    <BrowserRouter>
      <ScrollToTop />
      <ToastContainer
        position='top-right'
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/small-business' element={<SmallBusiness />} />
        <Route path='/commercial' element={<Commercial />} />
        <Route path='/wealth' element={<Wealth />} />
        <Route path='/about' element={<About />} />
        <Route
          path='/login'
          element={<Login notify={notify} Login={logUserIn} />}
        />
        <Route
          path='/forget-password'
          element={<ForgetPassword notify={notify} />}
        />
        <Route
          path='/reset-password/:email'
          element={<ResetPassword notify={notify} />}
        />
        <Route
          path='/admini'
          element={<AdminLogin notify={notify} Login={logAdminIn} />}
        />
        <Route path='/sign-up' element={<SignUp notify={notify} />} />
        <Route element={<AdminProtected notify={notify} loggedIn={adminIn} />}>
          <Route
            path='/admin/:page'
            element={<AdminDashboard notify={notify} />}
          />
          <Route
            path='/admin/:page/:id'
            element={<AdminDashboard notify={notify} />}
          />
        </Route>
        <Route element={<UserProtected notify={notify} loggedIn={loggedIn} />}>
          <Route
            path='/user/:page'
            element={<UserDashboard notify={notify} />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
