const PersonalCards = [
  {
    icon: 'fam-checking',
    details: 'Open an account',
  },
  {
    icon: 'fam-credit-cards',
    details: 'Choose a credit card',
  },
  {
    icon: 'fam-investments',
    details: 'Self-Directed Investing',
  },
  {
    icon: 'fam-home-loans',
    details: 'Mortgages',
  },
  {
    icon: 'fam-savings',
    details: 'Savings',
  },
  {
    icon: 'fam-borrow',
    details: 'Loans',
  },
]

export default PersonalCards
