import React, { useState } from 'react'
import { ReactComponent as Logo } from '../../../../assets/images/favicon.svg'
import Loader from '../../../../components/loader/Loader'
import { post } from '../../../../utilities/API/api'
import { useGlobalContext } from '../../../../utilities/Context/Context'

function TransferSavings({ notify }) {
  const { setShowTransferSavings } = useGlobalContext()
  const Token = sessionStorage.getItem('UserToken')
  const [amount, setAmount] = useState(0)
  const [formLoading, setFormLoading] = useState(false)
  const [pin, setPin] = useState('')

  function submit() {
    if (amount < 1) {
      notify('error', 'Amount is compulsory')
    } else {
      setFormLoading(true)
      const postData = {
        amount: parseFloat(amount),
      }
      post(`users/transaction-pin/verify`, { transactionPin: pin }, Token)
        .then((result) => {
          if (Object.keys(result).includes('error')) {
            notify('error', result.error)
            setFormLoading(false)
          } else {
            post(`users/savings/transfer`, postData, Token)
              .then((result) => {
                if (Object.keys(result).includes('error')) {
                  notify('error', result.error)
                } else {
                  notify('success', 'Transfer successful.')
                  setTimeout(() => {
                    window.location.reload()
                  }, 2000)
                }

                setFormLoading(false)
              })
              .catch((error) => {
                setFormLoading(false)
              })
          }
        })
        .catch((error) => {
          setFormLoading(false)
        })
    }
  }
  return (
    <div className='modal-type flex justify-center'>
      <div className='modal-body'>
        <div className='top '>
          <Logo />
          <h1>Transfer savings fund.</h1>
          <p style={{ textAlign: 'center' }}>
            Transfer amount from your savings account to your main account.
          </p>
        </div>
        <div className='form'>
          <div className='form-card'>
            <p>Amount</p>
            <input
              type='number'
              name=''
              id=''
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
          <div className='form-card'>
            <p>Transaction Pin</p>
            <input
              type='password'
              name='pin'
              id='pin'
              value={pin}
              maxLength={4}
              pattern='\d*'
              onChange={(e) => {
                if (/^\d*$/.test(e.target.value)) {
                  // Limit the input to 4 digits
                  setPin(e.target.value.slice(0, 4))
                }
              }}
            />
          </div>
          {formLoading ? (
            <Loader location='center' />
          ) : (
            <div className='form-card button'>
              <button
                onClick={() => setShowTransferSavings(false)}
                className='danger'
              >
                Cancel
              </button>
              <button onClick={submit}>Transfer funds</button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TransferSavings
