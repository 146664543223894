import React, { useState } from 'react'
import { post } from '../../../../utilities/API/api'
import Loader from '../../../../components/loader/Loader'

function Support({ notify }) {
  const Token = sessionStorage.getItem('UserToken')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [formLoading, setFormLoading] = useState(false)

  const submit = async () => {
    if (subject.trim() === '') {
      notify('error', 'Subject is compulsory')
    } else if (message.trim() === '') {
      notify('error', 'Message is compulsory')
    } else {
      setFormLoading(true)
      const postData = {
        subject: subject,
        message: message,
      }
      post('users/contact', postData, Token)
        .then((result) => {
          if (Object.keys(result).includes('error')) {
            notify('error', result.error)
          } else {
            notify(
              'success',
              'Complaint received and is currently being reviewed.'
            )
            setTimeout(() => {
              window.location.reload()
            }, 3000)
          }
          setFormLoading(false)
        })
        .catch((error) => {
          setFormLoading(false)
        })
    }
  }
  return (
    <div className='user-home'>
      <div className='home_tables'>
        <div className='left' style={{ minHeight: '60vh' }}>
          <div className='form'>
            <div className='form-card'>
              <p>Subject</p>
              <input type='text' onChange={(e) => setSubject(e.target.value)} />
            </div>
            <div className='form-card'>
              <p>Message</p>
              <textarea
                name=''
                id=''
                cols='30'
                rows='10'
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            {formLoading ? (
              <Loader location={'center'} />
            ) : (
              <div className='button text-center'>
                <button className='button-bold ' onClick={submit}>
                  Submit
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Support
