import React from 'react'

function CreditCard({ setClose }) {
  return (
    <div className='popup'>
      <div className='pop-cover'>
        <div className='tittle'>
          <h1>Apply for a credit card that works for you</h1>
        </div>
        <p>
          With our newly improved suite of personal credit cards, it's easy to
          find the most rewarding match for your spending style. Whether you
          love to travel, earn unlimited cash back or simply build credit, we've
          got the card for you.
        </p>
        <p>
          Earn more points on travel and get exclusive travel rewards, including
          an ancillary $100 annual travel credit.
        </p>
        <p>
          3 points for travel, 2 points for dining and 1.5 points for every $1
          spent on qualifying purchases
        </p>
        <p>No earnings cap and points never expire</p>
        <p>No foreign transaction fees</p>
        <p>$95 annual fee, waived the first year</p>
        <p>
          1.5% unlimited cash back on every $1 spent on qualifying purchases
        </p>
        <div className='button'>
          <button onClick={() => setClose(false)}>close</button>
        </div>
      </div>
    </div>
  )
}

export default CreditCard
