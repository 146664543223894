import React from 'react'

function Insights({ content, data }) {
  return (
    <div className='insight justify-center'>
      <div className='insight-cover'>
        <div className='top flex'>
          <h5>INSIGHTS</h5>
          <h2>{content}</h2>
        </div>
        <div className='cards flex flex-wrap justify-between'>
          {data.map((item) => (
            <div className='card' key={item.id}>
              <div className='img'>
                <img src={item.image} alt='' />
              </div>
              <div className='text'>
                <div className='text-cover'>
                  <h4>{item.header}</h4>
                  <h2>
                    {item.details} {'>'}
                  </h2>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Insights
