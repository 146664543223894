import React, { useEffect, useState } from 'react'
import { useGlobalContext } from '../../../../utilities/Context/Context'
import Loader from '../../../../components/loader/Loader'
import Pagination from '../../../../components/pagination/Pagination'
import { get } from '../../../../utilities/API/api'

function Loan({ notify }) {
  const token = sessionStorage.getItem('UserToken')
  const { setShowLoan } = useGlobalContext()
  const [loanData, setLoanData] = useState({})
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const paginationClick = (num) => {
    setLoading(true)
    FetchLoanTransactions(num)
  }

  const FetchLoanTransactions = (page) => {
    get(`users/loan/history?pageSize=10&page=${page}`, token)
      .then((result) => {
        if (Object.keys(result).includes('error')) {
          notify('error', result.error)
          setLoading(false)
        } else {
          setLoanData(result)
          setLoading(false)
        }
      })
      .catch((error) => {
        setLoading(false)
      })
    // eslint-disable-next-line
  }
  useEffect(
    () => {
      FetchLoanTransactions(1)
    },
    // eslint-disable-next-line
    []
  )
  return loading ? (
    <Loader location='center' />
  ) : (
    <div className='user-home'>
      <div className='buttons-head'>
        <button onClick={() => setShowLoan(true)}>Request for Loan</button>
      </div>
      <div className='home_tables'>
        <div className='left' style={{ minHeight: '60vh' }}>
          <table>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Amount</th>
                <th>Duration</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {loanData.loans.map((item, i) => (
                <tr key={item._id}>
                  <td>{i + 1}</td>
                  <td>${item.amount.toLocaleString()}</td>
                  <td>{item.duration}</td>
                  <td className='count'>
                    {new Date(item.createdAt).toDateString()}, {''}
                    {new Date(item.createdAt).toLocaleTimeString()}
                  </td>
                  <td>{item.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='mobile-table'>
            {loanData.loans.map((item, i) => (
              <div className='row' key={item._id}>
                <p>
                  <b>S/N</b> : {i + 1}
                </p>
                <p>
                  <b>Amount</b> : ${item.amount.toLocaleString()}
                </p>
                <p>
                  <b>Duration</b> : {item.duration}
                </p>
                <p>
                  <b>Date</b> : {new Date(item.createdAt).toDateString()},{' '}
                  {new Date(item.createdAt).toLocaleTimeString()}
                </p>
                <p>
                  <b>Status</b> : {item.status}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Pagination
        currentPage={currentPage}
        totalCount={loanData.totalPages}
        pageSize={10}
        onPageChange={(page) => {
          setCurrentPage(page)
          paginationClick(page)
        }}
      />
    </div>
  )
}

export default Loan
