import React, { useState } from 'react'
import { ReactComponent as Logo } from '../../../../assets/images/favicon.svg'
import Loader from '../../../../components/loader/Loader'
import { post } from '../../../../utilities/API/api'
import { useGlobalContext } from '../../../../utilities/Context/Context'

function LoanModal({ notify }) {
  const { setShowLoan } = useGlobalContext()
  const Token = sessionStorage.getItem('UserToken')
  const [amount, setAmount] = useState(0)
  const [duration, setDuration] = useState('')
  const [formLoading, setFormLoading] = useState(false)
  const [pin, setPin] = useState('')

  function submit() {
    if (amount < 1) {
      notify('error', 'Amount is compulsory')
    } else if (duration.length < 1) {
      notify('error', 'Duration is compulsory')
    } else {
      setFormLoading(true)
      const postData = {
        amount: parseFloat(amount),
        duration: duration,
      }

      post(`users/transaction-pin/verify`, { transactionPin: pin }, Token)
        .then((result) => {
          if (Object.keys(result).includes('error')) {
            notify('error', result.error)
            setFormLoading(false)
          } else {
            post(`users/loan`, postData, Token)
              .then((result) => {
                if (Object.keys(result).includes('error')) {
                  notify('error', result.error)
                } else {
                  notify('success', 'Loan request successful.')
                  setTimeout(() => {
                    window.location.reload()
                  }, 2000)
                }

                setFormLoading(false)
              })
              .catch((error) => {
                setFormLoading(false)
              })
          }
        })
        .catch((error) => {
          setFormLoading(false)
        })
    }
  }
  return (
    <div className='modal-type flex justify-center'>
      <div className='modal-body'>
        <div className='top '>
          <Logo />
          <h1>Loan Request</h1>
          <p>
            Please note that loan eligibility is determined by various factors,
            including creditworthiness. If, based on our assessment, you're not
            eligible for the requested loan amount, your application may be
            declined. We believe in responsible lending to ensure a positive and
            sustainable financial experience for all. Note that we have a
            standard interest of 12%.
          </p>
        </div>
        <div className='form'>
          <div className='form-card'>
            <p>Amount</p>
            <input
              type='number'
              name=''
              id=''
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
          <div className='form-card'>
            <p>Duration</p>
            <select name='' id='' onChange={(e) => setDuration(e.target.value)}>
              <option value=''>- select - </option>
              <option value='2 weeks'>2 Weeks</option>
              <option value='1 month'>1 months</option>
              <option value='2 months'>2 months</option>
              <option value='4 months'>4 months</option>
              <option value='6 months'>6 months</option>
              <option value='8 months'>8 months</option>
              <option value='1 year'>1 year</option>
            </select>
          </div>
          <div className='form-card'>
            <p>Transaction Pin</p>
            <input
              type='password'
              name='pin'
              id='pin'
              value={pin}
              maxLength={4}
              pattern='\d*'
              onChange={(e) => {
                if (/^\d*$/.test(e.target.value)) {
                  // Limit the input to 4 digits
                  setPin(e.target.value.slice(0, 4))
                }
              }}
            />
          </div>
          {formLoading ? (
            <Loader location='center' />
          ) : (
            <div className='form-card button'>
              <button onClick={() => setShowLoan(false)} className='danger'>
                Cancel
              </button>
              <button onClick={submit}>Request Loan</button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default LoanModal
