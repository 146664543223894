import React from 'react'
import './Cards.scss'
import { Icon } from '@iconify/react'
import { useGlobalContext } from '../../../../utilities/Context/Context'

function Cards() {
  const { setShowCard } = useGlobalContext()
  return (
    <div className='cards'>
      <div className='cards-top'>
        <h1>Request for a card</h1>
        <div
          className='card flex justify-center'
          onClick={() => setShowCard(true)}
        >
          <Icon icon='line-md:plus' />
        </div>
      </div>
    </div>
  )
}

export default Cards
