import React, { useState } from 'react'
import { ReactComponent as Logo } from '../../../../assets/images/favicon.svg'
import Loader from '../../../../components/loader/Loader'
import { put } from '../../../../utilities/API/api'
import { useGlobalContext } from '../../../../utilities/Context/Context'
import { useParams } from 'react-router-dom'

function RestrictUser({ notify }) {
  const { id } = useParams()
  const { setRestrictUser, restrictUser } = useGlobalContext()
  const Token = sessionStorage.getItem('AdminToken')
  const [formLoading, setFormLoading] = useState(false)
  function submit() {
    setFormLoading(true)
    const postData = {
      status: restrictUser,
    }
    put(`admin/users/restrict/${id}`, postData, Token)
      .then((result) => {
        if (Object.keys(result).includes('error')) {
          notify('error', result.error)
        } else {
          notify(
            'success',
            `User ${restrictUser ? 'restricted' : 'Un-restricted'}.`
          )
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        }

        setFormLoading(false)
      })
      .catch((error) => {
        setFormLoading(false)
      })
  }
  return (
    <div className='modal-type flex justify-center'>
      <div className='modal-body'>
        <div className='top '>
          <Logo />
          <h1>Restrict User</h1>
        </div>
        <div className='form'>
          {formLoading ? (
            <Loader location='center' />
          ) : (
            <div className='form-card button'>
              <button onClick={() => setRestrictUser(null)} className='danger'>
                Cancel
              </button>
              <button onClick={submit}>
                {restrictUser ? 'Restrict User' : 'Un-restrict User'}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default RestrictUser
