import React from 'react'
import './Profile.scss'
import { useGlobalContext } from '../../../../utilities/Context/Context'

function Profile() {
  const { userProfile, setShowProfileImage } = useGlobalContext()

  return (
    <div className='profile'>
      <div className='top'>
        <h1>Account Information</h1>
      </div>
      <div className='picture'>
        <h2>Profile picture change</h2>
        <div className='flex '>
          <div className='avatar flex justify-center'>
            {Object.keys(userProfile).includes('profile_image_url') ? (
              <img src={userProfile.profile_image_url} alt='' />
            ) : (
              <h3>
                {userProfile.firstName.charAt(0)}
                {userProfile.lastName.charAt(0)}
              </h3>
            )}
          </div>
          <button onClick={() => setShowProfileImage(true)}>Change</button>
        </div>
      </div>
      <div className='account'>
        <h2>Personal Information</h2>
        <div className='flex justify-between flex-wrap'>
          <div className='form-card'>
            <p>First name</p>
            <div className='input'>
              <h4>{userProfile.firstName}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Last name</p>
            <div className='input'>
              <h4>{userProfile.lastName}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>D.O.B</p>
            <div className='input'>
              <h4>{new Date(userProfile.dob).toDateString()}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Phone</p>
            <div className='input'>
              <h4>{userProfile.phone}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Email</p>
            <div className='input'>
              <h4>{userProfile.email}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Nationality</p>
            <div className='input'>
              <h4>{userProfile.nationality}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>SSN</p>
            <div className='input'>
              <h4>{userProfile.ssn.length > 1 ? userProfile.ssn : 'N/A'}</h4>
            </div>
          </div>
          <div className='form-card'>
            <p>Address</p>
            <div className='input'>
              <h4>{userProfile.address} </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
