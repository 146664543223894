import React from 'react'
import './Sidebar.scss'
import { useNavigate } from 'react-router-dom'
import { useGlobalContext } from '../../../../utilities/Context/Context'
import { Icon } from '@iconify/react'

function Sidebar({ page }) {
  const navigate = useNavigate()
  const { toggleSidebar, handleSidebarToggle } = useGlobalContext()

  return (
    <div className={toggleSidebar ? 'sidebar' : 'sidebar active'}>
      <div className='columns'>
        <div
          className={page === 'users' || page === 'user' ? 'row active' : 'row'}
          onClick={() => {
            navigate('/admin/users')
            handleSidebarToggle()
          }}
        >
          <div className='icon'>
            <Icon icon='solar:users-group-two-rounded-bold' />
          </div>
          <p>Users</p>
        </div>
        <div
          className={
            page === 'transactions' || page === 'transaction'
              ? 'row active'
              : 'row'
          }
          onClick={() => {
            navigate('/admin/transactions')
            handleSidebarToggle()
          }}
        >
          <div className='icon'>
            <Icon icon='line-md:list' />
          </div>
          <p>Transactions</p>
        </div>
        <div
          className={page === 'loans' ? 'row active' : 'row'}
          onClick={() => {
            navigate('/admin/loans')
            handleSidebarToggle()
          }}
        >
          <div className='icon'>
            <Icon icon='solar:hand-money-bold' />
          </div>
          <p>Loans</p>
        </div>
        <div
          className='row'
          onClick={() => {
            sessionStorage.clear()
            window.location.replace('/admini')
          }}
        >
          <div className='icon'>
            <Icon icon='line-md:log-out' />
          </div>
          <p>Log out</p>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
