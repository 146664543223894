import React, { useState } from 'react'
import './auth.scss'
import Logo from '../../assets/images/logo.png'
import { useNavigate } from 'react-router-dom'
import { postNo } from '../../utilities/API/api'
import Loader from '../../components/loader/Loader'
import Countries from '../../assets/data/Countries'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

function SignUp({ notify }) {
  const [emailAddress, setEmailAddress] = useState('')
  const [phone, setPhone] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [country, setCountry] = useState('')
  const [SSN, setSSN] = useState('')
  const [address, setAddress] = useState('')
  const [frontId, setFrontId] = useState(null)
  const [backId, setBackId] = useState(null)
  const [DOB, setDOB] = useState('')
  const [foreigner, setForeigner] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const navigate = useNavigate()

  const submit = async () => {
    if (emailAddress.trim() === '') {
      notify('error', 'Email address is compulsory')
    } else if (phone.trim() === '') {
      notify('error', 'Phone number is compulsory')
    } else if (firstName.trim() === '') {
      notify('error', 'First name is compulsory')
    } else if (lastName.trim() === '') {
      notify('error', 'Last name is compulsory')
    } else if (country.trim() === '') {
      notify('error', 'Country is compulsory')
    } else if (!foreigner && SSN.trim() === '') {
      notify('error', 'SSN is compulsory')
    } else if (address.trim() === '') {
      notify('error', 'Address is compulsory')
    } else if (frontId === null) {
      notify('error', 'Front ID is compulsory')
    } else if (backId === null) {
      notify('error', 'Back ID is compulsory')
    } else if (DOB.length < 1) {
      notify('error', 'Date of Birth is compulsory')
    } else {
      setFormLoading(true)
      const front = await handleUpload(frontId)
      const back = await handleUpload(backId)
      const postData = {
        email: emailAddress,
        phone: phone,
        firstName: firstName,
        lastName: lastName,
        nationality: country,
        ssn: SSN,
        address: address,
        idFront: front,
        idBack: back,
        dob: DOB,
        foreigner: foreigner,
      }

      postNo('user/signup', postData)
        .then((result) => {
          if (Object.keys(result).includes('error')) {
            notify('error', result.error)
          } else {
            notify(
              'success',
              'Details have been received and is currently being reviewed.'
            )
            setTimeout(() => {
              navigate('/')
            }, 3000)
          }
          setFormLoading(false)
        })
        .catch((error) => {
          setFormLoading(false)
        })
    }
  }
  const handleUpload = async (id) => {
    try {
      // Create FormData and append the selected file
      const formData = new FormData()
      formData.append('image', id)

      // Make a POST request to your Node.js server
      const response = await fetch(
        'https://fcd-be-e3a65f61233d.herokuapp.com/upload',
        {
          method: 'POST',
          body: formData,
        }
      )

      // Parse the JSON response
      const data = await response.json()

      // Set the imageUrl state with the Cloudinary URL
      return data.imageUrl
    } catch (error) {
      console.error('Error uploading image:', error)
    }
  }
  return (
    <div className='flex justify-center auth'>
      <div className='modal dif'>
        <div className='modal-wrap'>
          <div className='top'>
            <img src={Logo} alt='' onClick={() => navigate('/')} />
          </div>
          <div className='form'>
            <div className='form-cover flex signup justify-between'>
              <div className='form-card'>
                <p>First name</p>
                <input
                  type='text'
                  name=''
                  id=''
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className='form-card'>
                <p>Last name</p>
                <input
                  type='text'
                  name=''
                  id=''
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className='form-card'>
                <p>Email</p>
                <input
                  type='email'
                  name=''
                  id=''
                  onChange={(e) => setEmailAddress(e.target.value)}
                />
              </div>
              <div className='form-card'>
                <p>Phone number</p>
                <input
                  type='tel'
                  name=''
                  id=''
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder='XXX - XXX - XXXX'
                />
              </div>
              <div className='form-card'>
                <p>Country</p>
                <select
                  name=''
                  id=''
                  onChange={(e) => setCountry(e.target.value)}
                >
                  <option value=''>- select -</option>
                  {Countries.map((item) => (
                    <option value={item.name} key={item.code}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className='form-card'>
                <p>Date of Birth </p>
                <DatePicker
                  placeholderText='MM / DD / YYYY'
                  selected={DOB}
                  onChange={(date) => setDOB(date)}
                />
              </div>
              <div className='form-card'>
                <p>Valid ID (front)</p>
                <input
                  type='file'
                  name=''
                  id=''
                  accept='image/*'
                  onChange={(e) => setFrontId(e.target.files[0])}
                />
              </div>
              <div className='form-card'>
                <p>Valid ID (Back)</p>
                <input
                  type='file'
                  name=''
                  id=''
                  accept='image/*'
                  onChange={(e) => setBackId(e.target.files[0])}
                />
              </div>
              <div className='form-card long'>
                <p>Social Security Number ( N/A if foreigner.) </p>
                <input
                  type='number'
                  name=''
                  id=''
                  onChange={(e) => setSSN(e.target.value)}
                  placeholder='XXX - XX - XXXX'
                />
              </div>

              <div className='form-card long'>
                <p>Address </p>
                <input
                  type='text'
                  name=''
                  id=''
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div className='form-card long check flex'>
                <input
                  type='checkbox'
                  name=''
                  id=''
                  onChange={(e) => {
                    setForeigner(!foreigner)
                  }}
                />
                <label htmlFor='check'>Not a US citizen?</label>
              </div>
              {formLoading ? (
                <Loader location='center' />
              ) : (
                <div className='form-card long'>
                  <button onClick={submit} className='submit'>
                    Sign up
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUp
