import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

function UserProtected({ loggedIn, notify }) {
  const token = sessionStorage.getItem('UserToken')
  if (loggedIn) {
    return <Outlet />
  } else if (token) {
    return <Outlet />
  } else {
    return (
      <>
        {notify('error', 'Access denied! Login again.')}
        <Navigate to='/login' />
      </>
    )
  }
}

export default UserProtected
