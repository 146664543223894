import React from 'react'
import { Icon } from '@iconify/react'
import './Topbar.scss'
import { useGlobalContext } from '../../../../utilities/Context/Context'

function Topbar({ page }) {
  const { handleSidebarToggle, userProfile } = useGlobalContext()
  function fetchHeader() {
    if (page === 'home') {
      return 'Home'
    } else if (page === 'transactions') {
      return 'Transactions'
    } else if (page === 'loan') {
      return 'Loan'
    } else if (page === 'savings') {
      return 'Savings'
    } else if (page === 'cards') {
      return 'Cards'
    } else if (page === 'support') {
      return 'Contact Us'
    }
  }

  return (
    <div className='flex justify-between user-topbar '>
      <div className='left flex'>
        <Icon icon='fa6-solid:bars' onClick={handleSidebarToggle} />
        <h1>{fetchHeader()}</h1>
      </div>

      <div className='right'>
        <div className='image flex justify-center'>
          {Object.keys(userProfile).includes('profile_image_url') ? (
            <img src={userProfile.profile_image_url} alt='' />
          ) : (
            <h3>
              {userProfile.firstName.charAt(0)}
              {userProfile.lastName.charAt(0)}
            </h3>
          )}
        </div>
      </div>
    </div>
  )
}

export default Topbar
