import React from 'react'

function Cancer({ setClose }) {
  return (
    <div className='popup'>
      <div className='pop-cover'>
        <div className='tittle'>
          <h1>Helping teens with cancer feel like teens again</h1>
        </div>
        <p>
          Being a teen is hard enough. Most young people diagnosed with cancer
          won't have a dedicated place for their treatment in a hospital. Help
          us support Teen Cancer America to improve their quality of care.
        </p>
        <p>
          We've supported Teen Cancer America since 2015 when we met TCA founder
          and rock icon Roger Daltrey of The Who. Together, we can raise
          critical awareness and substantial funding for TCA hospitals
          throughout our markets. Most importantly, we can transform lives and
          empower the future—improving the experience, outcomes and survival of
          teens and young adults with cancer by providing facilities and
          programs designed especially for them.
        </p>
        <p>
          Your donations funded a dedicated patient navigator at Prisma Health
          Cancer Institute in Greenville, South Carolina, who helps young
          patients manage the challenges of a cancer diagnosis while they pursue
          and undergo treatment.
        </p>
        <p>
          In 2019, your donations helped us open the first dedicated cancer care
          unit for young people in the Carolinas—the Hawkins Family Adolescent
          and Young Adult Cancer Center at Bon Secours St. Francis Hospital in
          Greenville, South Carolina.
        </p>
        <p>
          In 2018, we partnered with the University of North Carolina's
          Lineberger Comprehensive Cancer Center in Chapel Hill to enhance its
          existing Adolescent and Young Adult cancer program, expand research
          initiatives and help establish a dedicated space for specialized
          cancer support.
        </p>
        <div className='button'>
          <button onClick={() => setClose(false)}>close</button>
        </div>
      </div>
    </div>
  )
}

export default Cancer
