import React from 'react'
// import { useNavigate } from 'react-router-dom'

function Forever() {
  // const navigate = useNavigate()
  return (
    <div className='forever justify-center'>
      <div className='forever-cover'>
        <div className='flex justify-between flex-wrap'>
          <div className='card'>
            <h2>Our Forever First Promise</h2>
            <p>
              Forever First® means the name on our door will stay the same for
              years to come.
            </p>
          </div>
          <div className='card'>
            <h2>Forever Stable</h2>
            <p>
              Taking care of customers—year in, year out—isn't just our track
              record. It's our promise.
            </p>
          </div>
          <div className='card'>
            <h2>Forever Family</h2>
            <p>
              We're one of America's largest family-controlled banks, led for
              three generations by members of one family.
            </p>
          </div>
        </div>
        {/* <button
          className='button-outline button-green'
          onClick={() => navigate('/about')}
        >
          About Mint Capital
        </button> */}
      </div>
    </div>
  )
}

export default Forever
