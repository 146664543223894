import React, { useState } from 'react'
import './Footer.scss'
import { Icon } from '@iconify/react'
import { Link } from 'react-router-dom'
import Accessibility from './popover/Accessibility'
import RoutingNumber from './popover/RoutingNumber'
import Privacy from './popover/Privacy'
import Community from './popover/Community'
import Terms from './popover/Terms'
import Email from './popover/Email'

function Footer() {
  const [accessible, setAccessible] = useState(false)
  const [routingNumber, setRoutingNumber] = useState(false)
  const [privacy, setPrivacy] = useState(false)
  const [community, setCommunity] = useState(false)
  const [terms, setTerms] = useState(false)
  const [email, setEmail] = useState(false)
  return (
    <>
      {/* <div className='popup'>
        <div className='pop-cover'>
          <div className='tittle'>
            <h1>Title</h1>
          </div>
          <p>Info</p>
          <div className='button'>
            <button>close</button>
          </div>
        </div>
      </div> */}
      {accessible && <Accessibility setClose={setAccessible} />}
      {routingNumber && <RoutingNumber setClose={setRoutingNumber} />}
      {privacy && <Privacy setClose={setPrivacy} />}
      {community && <Community setClose={setCommunity} />}
      {terms && <Terms setClose={setTerms} />}
      {email && <Email setClose={setEmail} />}
      <div className='footer justify-center'>
        <div className='footer-cover'>
          <div className=' cards flex-wrap'>
            <div className='card'>
              <h2>Contact</h2>
              <ul>
                {/* <Link to={'/'}>
                <li className='flex'>
                  Call Us <Icon icon='icon-park-outline:right' />
                </li>
              </Link> */}
                <li className='flex' onClick={() => setEmail(true)}>
                  Email Us <Icon icon='icon-park-outline:right' />
                </li>
              </ul>
            </div>
            <div className='card'>
              <h2>Support</h2>
              <ul>
                <li className='flex' onClick={() => setAccessible(true)}>
                  Accessibility <Icon icon='icon-park-outline:right' />
                </li>
                <li className='flex' onClick={() => setRoutingNumber(true)}>
                  Routing Number <Icon icon='icon-park-outline:right' />
                </li>
                <li className='flex' onClick={() => setPrivacy(true)}>
                  Privacy & Security <Icon icon='icon-park-outline:right' />
                </li>
              </ul>
            </div>
            <div className='card'>
              <h2>Company</h2>
              <ul>
                <Link to={'/'}>
                  {/* <li className='flex'>
                  About Us <Icon icon='icon-park-outline:right' />
                </li> */}
                </Link>
                <li className='flex' onClick={() => setCommunity(true)}>
                  Community Support <Icon icon='icon-park-outline:right' />
                </li>
                <li className='flex' onClick={() => setTerms(true)}>
                  Terms of Use <Icon icon='icon-park-outline:right' />
                </li>
              </ul>
            </div>
            <div className='card'>
              <h2>Banking</h2>
              <ul>
                <Link to={'/'}>
                  <li className='flex'>
                    Personal <Icon icon='icon-park-outline:right' />
                  </li>
                </Link>
                <Link to={'/small-business'}>
                  <li className='flex'>
                    Small Business <Icon icon='icon-park-outline:right' />
                  </li>
                </Link>
                <Link to={'/commercial'}>
                  <li className='flex'>
                    Commercial <Icon icon='icon-park-outline:right' />
                  </li>
                </Link>
                <Link to={'/wealth'}>
                  <li className='flex'>
                    Wealth <Icon icon='icon-park-outline:right' />
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
