import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

function AdminProtected({ loggedIn, notify }) {
  const token = sessionStorage.getItem('AdminToken')
  if (loggedIn) {
    return <Outlet />
  } else if (token) {
    return <Outlet />
  } else {
    return (
      <>
        {notify('error', 'Access denied! You should not be here')}
        <Navigate to='/admini' />
      </>
    )
  }
}

export default AdminProtected
