import React, { useEffect, useState } from 'react'
import Loader from '../../../../components/loader/Loader'
import Pagination from '../../../../components/pagination/Pagination'
import { get, put } from '../../../../utilities/API/api'

function AdminLoans({ notify }) {
  const token = sessionStorage.getItem('AdminToken')
  const [loanData, setLoanData] = useState({})
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [formLoading, setFormLoading] = useState(false)
  const [loanID, setLoanID] = useState('')
  const paginationClick = (num) => {
    setLoading(true)
    FetchLoanTransactions(num)
  }

  const FetchLoanTransactions = (page) => {
    get(`admin/loan/history?pageSize=10&page=${page}`, token)
      .then((result) => {
        if (Object.keys(result).includes('error')) {
          notify('error', result.error)
          setLoading(false)
        } else {
          setLoanData(result)
          setLoading(false)
        }
      })
      .catch((error) => {
        setLoading(false)
      })
    // eslint-disable-next-line
  }
  useEffect(
    () => {
      FetchLoanTransactions(1)
    },
    // eslint-disable-next-line
    []
  )
  function decline(id) {
    setLoanID(id)
    setFormLoading(true)
    put(`admin/loan/decline?id=${id}`, null, token)
      .then((result) => {
        if (Object.keys(result).includes('error')) {
          notify('error', result.error)
        } else {
          notify('success', 'Loan request Declined.')
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        }
        setFormLoading(false)
      })
      .catch((error) => {
        setFormLoading(false)
      })
  }
  function approve(id) {
    setLoanID(id)
    setFormLoading(true)
    put(`admin/loan/approve?id=${id}`, null, token)
      .then((result) => {
        if (Object.keys(result).includes('error')) {
          notify('error', result.error)
        } else {
          notify('success', 'Loan request approved.')
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        }
        setFormLoading(false)
      })
      .catch((error) => {
        setFormLoading(false)
      })
  }
  return loading ? (
    <Loader location='center' />
  ) : (
    <div className='home_page'>
      <div className='home_tag'>
        <h1>Loans</h1>
      </div>
      <div className='home_tables'>
        <div className='left' style={{ minHeight: '60vh' }}>
          <table>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Amount</th>
                <th>Duration</th>
                <th>Date</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loanData.loans.map((item, i) => (
                <tr key={item._id}>
                  <td>{i + 1}</td>
                  <td>${item.amount.toLocaleString()}</td>
                  <td>{item.duration}</td>
                  <td className='count'>
                    {new Date(item.createdAt).toDateString()}, {''}
                    {new Date(item.createdAt).toLocaleTimeString()}
                  </td>
                  <td>{item.status}</td>
                  <td>
                    {item.status === 'pending' && (
                      <>
                        {loanID === item._id && formLoading ? (
                          <Loader location={'center'} />
                        ) : (
                          <>
                            <button onClick={() => approve(item._id)}>
                              Approve
                            </button>
                            <button
                              className='danger'
                              onClick={() => decline(item._id)}
                            >
                              Decline
                            </button>
                          </>
                        )}
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        currentPage={currentPage}
        totalCount={loanData.totalPages}
        pageSize={10}
        onPageChange={(page) => {
          setCurrentPage(page)
          paginationClick(page)
        }}
      />
    </div>
  )
}

export default AdminLoans
