import React, { useEffect } from 'react'
import './Home.scss'
import { Icon } from '@iconify/react'
import { useGlobalContext } from '../../../../utilities/Context/Context'
import Loader from '../../../../components/loader/Loader'
import { useNavigate } from 'react-router-dom'

function Home() {
  const navigate = useNavigate()
  const {
    userProfile,
    fetchUserTransactions,
    userTransactionsLoading,
    userTransactions,
    setReceiptData,
    addComma,
  } = useGlobalContext()
  useEffect(
    () => {
      fetchUserTransactions(1)
    },
    // eslint-disable-next-line
    []
  )

  return (
    <div className='user-home'>
      <div className='home_card'>
        <div className='card'>
          <div className='left one'>
            <div className='round'>
              <Icon icon='bi:wallet-fill' />
            </div>
          </div>
          <div className='right'>
            <h2>${addComma(userProfile.balance)}</h2>
            <p>Account Balance</p>
          </div>
        </div>
        <div className='card'>
          <div className='left two'>
            <div className='round'>
              <Icon icon='fluent-emoji-high-contrast:money-bag' />
            </div>
          </div>
          <div className='right'>
            <h2>${addComma(userProfile.loan_balance)}</h2>
            <p>Loaned Balance</p>
          </div>
        </div>
        <div className='card'>
          <div className='left three'>
            <div className='round'>
              <Icon icon='vaadin:piggy-bank-coin' />
            </div>
          </div>
          <div className='right'>
            <h2>${userProfile.savings_balance.toLocaleString()}</h2>
            <p>Savings Balance</p>
          </div>
        </div>
      </div>
      <div className='home_tables'>
        <div className='left'>
          <div className='flex acc flex-wrap'>
            <div className='acc-card'>
              <h2>Account number</h2>
              <h3>{userProfile.account_number}</h3>
            </div>
            <div className='acc-card'>
              <h2>Routing number</h2>
              <h3>{userProfile.routing_number}</h3>
            </div>
            <div className='acc-card'>
              <h2>Swift code</h2>
              <h3>{userProfile.swift_code}</h3>
            </div>
          </div>
        </div>
      </div>
      {userTransactionsLoading ? (
        <Loader location='center' />
      ) : (
        <div className='home_tables'>
          <div className='left' style={{ minHeight: '60vh' }}>
            <div className='left_top flex justify-between'>
              <h2>Transactions</h2>
              <button onClick={() => navigate('/user/transactions')}>
                view all
              </button>
            </div>
            <table>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Amount</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {userTransactions.transactions.map((item, i) => (
                  <tr key={item._id}>
                    <td>{i + 1}</td>
                    <td>${item.amount.toLocaleString()}</td>
                    <td className='count'>{item.type}</td>
                    <td className='count'>
                      {new Date(item.createdAt).toDateString()}, {''}
                      {new Date(item.createdAt).toLocaleTimeString()}
                    </td>
                    <td>{item.state}</td>
                    <td>
                      <Icon
                        icon='fluent:open-24-filled'
                        onClick={() => setReceiptData(item)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className='mobile-table'>
              {userTransactions.transactions.map((item, i) => (
                <div className='row' key={item._id}>
                  <p>
                    <b>S/N</b> : {i + 1}
                  </p>
                  <p>
                    <b>Amount</b> : ${item.amount.toLocaleString()}
                  </p>
                  <p>
                    <b>Type</b> : {item.type}
                  </p>
                  <p>
                    <b>Date</b> : {new Date(item.createdAt).toDateString()},{' '}
                    {new Date(item.createdAt).toLocaleTimeString()}
                  </p>
                  <p>
                    <b>Status</b> : {item.state}
                  </p>
                  <button onClick={() => setReceiptData(item)}>
                    view receipt
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Home
