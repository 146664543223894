import React from 'react'

function BusinessSavings({ setClose }) {
  return (
    <div className='popup'>
      <div className='pop-cover'>
        <div className='tittle'>
          <h1>Let a business savings account put your extra cash to work</h1>
        </div>
        <p>
          If you operate a larger-scale enterprise, our commercial savings
          accounts can give you the flexibility you need to reach both short-
          and long-term goals.
        </p>
        <p>Maximized savings potential with tiered interest rates</p>
        <p>Steady interest rates and flexible terms on CDs</p>
        <p>
          You'll get a financial cushion by saving extra cash flow for future
          expenses.
        </p>
        <p>
          You'll earn interest on the money you deposit into a business savings
          account.
        </p>
        <div className='button'>
          <button onClick={() => setClose(false)}>close</button>
        </div>
      </div>
    </div>
  )
}

export default BusinessSavings
