import React from 'react'

function BusinessAccount({ setClose }) {
  return (
    <div className='popup'>
      <div className='pop-cover'>
        <div className='tittle'>
          <h1>Business checking services that meet you where you are</h1>
        </div>
        <p>
          Whether your business is starting or expanding, our small business
          checking accounts were designed with your needs in mind. Customize
          your package by adding on discounted treasury management products, and
          leverage your Mint capital alliance merchant services account to cover
          the monthly maintenance fee.
        </p>
        <p>
          No monthly maintenance fee with an active Mint capital alliance
          merchant services account or a $75,000 average daily ledger balance
        </p>
        <p>No statement fee when enrolled in paperless statements</p>
        <p>Up to 750 free transactions per month</p>
        <p>$20,000 cash processing each month11 at no charge</p>
        <div className='button'>
          <button onClick={() => setClose(false)}>close</button>
        </div>
      </div>
    </div>
  )
}

export default BusinessAccount
