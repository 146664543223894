import React, { useEffect, useState } from 'react'
import { useGlobalContext } from '../../../../utilities/Context/Context'
import Loader from '../../../../components/loader/Loader'
import Pagination from '../../../../components/pagination/Pagination'
import { get } from '../../../../utilities/API/api'

function Savings({ notify }) {
  const token = sessionStorage.getItem('UserToken')
  const { setShowSavings, userProfile, setShowTransferSavings } =
    useGlobalContext()
  const [savingsData, setSavingsData] = useState({})
  const [loading, setLoading] = useState(true)
  const [saveData, setSaveData] = useState({})
  const [saveLoading, setSaveLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPage2, setCurrentPage2] = useState(1)
  const paginationClick = (num) => {
    setLoading(true)
    FetchSavingsTransactions(num)
  }
  const paginationClick2 = (num) => {
    setSaveLoading(true)
    FetchSavings(num)
  }

  const FetchSavingsTransactions = (page) => {
    get(`users/savings-transactions/history?pageSize=10&page=${page}`, token)
      .then((result) => {
        if (Object.keys(result).includes('error')) {
          notify('error', result.error)
          setLoading(false)
        } else {
          setSavingsData(result)
          setLoading(false)
        }
      })
      .catch((error) => {
        setLoading(false)
      })
    // eslint-disable-next-line
  }
  const FetchSavings = (page) => {
    get(`users/savings/history?pageSize=10&page=${page}`, token)
      .then((result) => {
        if (Object.keys(result).includes('error')) {
          notify('error', result.error)
          setSaveLoading(false)
        } else {
          setSaveData(result)
          setSaveLoading(false)
        }
      })
      .catch((error) => {
        setSaveLoading(false)
      })
    // eslint-disable-next-line
  }
  useEffect(
    () => {
      FetchSavingsTransactions(1)
      FetchSavings(1)
    },
    // eslint-disable-next-line
    []
  )
  return loading ? (
    <Loader location='center' />
  ) : (
    <div className='user-home'>
      <div className='buttons-head left'>
        <button onClick={() => setShowSavings(true)}>Create saving plan</button>
        <button onClick={() => setShowTransferSavings(true)} className='new'>
          Transfer
        </button>
      </div>
      <div className='home_tables'>
        <div className='left' style={{ minHeight: '60vh' }}>
          <div className='left_top flex justify-between'>
            <h2>Saving Transactions</h2>
            <button
              className={
                userProfile.savings_balance > 1 ? 'balance' : 'non-balance'
              }
            >
              Balance : ${userProfile.savings_balance.toLocaleString()}
            </button>
          </div>
          <table>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Credited to</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {savingsData.transactions.map((item, i) => (
                <tr key={item._id}>
                  <td>{i + 1}</td>
                  <td>${item.amount.toLocaleString()}</td>
                  <td>{item.type}</td>
                  <td>{item.credited_to}</td>
                  <td className='count'>
                    {new Date(item.createdAt).toDateString()}, {''}
                    {new Date(item.createdAt).toLocaleTimeString()}
                  </td>
                  <td>{item.state}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='mobile-table'>
            {savingsData.transactions.map((item, i) => (
              <div className='row' key={item._id}>
                <p>
                  <b>S/N</b> : {i + 1}
                </p>
                <p>
                  <b>Amount</b> : ${item.amount.toLocaleString()}
                </p>
                <p>
                  <b>Type</b> : {item.type}
                </p>
                <p>
                  <b>Credited to</b> : {item.credited_to}
                </p>
                <p>
                  <b>Date</b> : {new Date(item.createdAt).toDateString()},{' '}
                  {new Date(item.createdAt).toLocaleTimeString()}
                </p>
                <p>
                  <b>Status</b> : {item.state}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Pagination
        currentPage={currentPage}
        totalCount={savingsData.totalPages}
        pageSize={10}
        onPageChange={(page) => {
          setCurrentPage(page)
          paginationClick(page)
        }}
      />
      {saveLoading ? (
        <Loader location='center' />
      ) : (
        <>
          <div className='home_tables'>
            <div className='left' style={{ minHeight: '60vh' }}>
              <div className='left_top flex justify-between'>
                <h2>Savings History</h2>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Amount</th>
                    <th>Days left</th>
                    <th>Daily amount</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {saveData.savings.map((item, i) => (
                    <tr key={item._id}>
                      <td>{i + 1}</td>
                      <td>${item.amount.toLocaleString()}</td>
                      <td>{item.savingsDurationDays}</td>
                      <td>${item.savingsDailyAmount.toLocaleString()}</td>
                      <td className='count'>
                        {new Date(item.createdAt).toDateString()}, {''}
                        {new Date(item.createdAt).toLocaleTimeString()}
                      </td>
                      <td>{item.isActive ? 'Running' : 'Ended'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className='mobile-table'>
                {saveData.savings.map((item, i) => (
                  <div className='row' key={item._id}>
                    <p>
                      <b>S/N</b> : {i + 1}
                    </p>
                    <p>
                      <b>Amount</b> : ${item.amount.toLocaleString()}
                    </p>
                    <p>
                      <b>Days left</b> : {item.savingsDurationDays}
                    </p>
                    <p>
                      <b>Daily amount</b> : $
                      {item.savingsDailyAmount.toLocaleString()}
                    </p>
                    <p>
                      <b>Date</b> : {new Date(item.createdAt).toDateString()},{' '}
                      {new Date(item.createdAt).toLocaleTimeString()}
                    </p>
                    <p>
                      <b>Status</b> : {item.isActive ? 'Running' : 'Ended'}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Pagination
            currentPage={currentPage2}
            totalCount={saveData.totalPages}
            pageSize={10}
            onPageChange={(page) => {
              setCurrentPage2(page)
              paginationClick2(page)
            }}
          />
        </>
      )}
    </div>
  )
}

export default Savings
