import React from 'react'
import './Topbar.scss'
import Logo from '../../../../assets/images/logo.png'
import { Icon } from '@iconify/react'
import { useGlobalContext } from '../../../../utilities/Context/Context'
function Topbar() {
  const { handleSidebarToggle } = useGlobalContext()
  return (
    <div className='admin-topbar'>
      <div className='logo'>
        <div className='toggle'>
          <Icon icon='fa6-solid:bars' onClick={handleSidebarToggle} />
        </div>
        <img src={Logo} alt='' />
      </div>
      <div className='rest'>
        <div className='page_name'></div>
        <div className='may_account'>
          <div className='account_head'>
            <Icon icon='ph:user-duotone' className='user' />
            <p>Welcome Admin</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Topbar
