import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { get } from '../../../../utilities/API/api'
import Loader from '../../../../components/loader/Loader'
import Pagination from '../../../../components/pagination/Pagination'
import { useGlobalContext } from '../../../../utilities/Context/Context'
import { Icon } from '@iconify/react'

function Transactions({ notify }) {
  const navigate = useNavigate()
  const { setReceiptData } = useGlobalContext()
  const token = sessionStorage.getItem('AdminToken')
  const [transactionsData, setTransactionsData] = useState({})
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)

  //////////////////////////
  const FetchTransactions = (page) => {
    get(`admin/transfer/history?pageSize=10&page=${page}`, token)
      .then((result) => {
        if (Object.keys(result).includes('error')) {
          notify('error', result.error)
          setLoading(false)
        } else {
          setTransactionsData(result)
          setLoading(false)
        }
      })
      .catch((error) => {
        setLoading(false)
      })
    // eslint-disable-next-line
  }

  useEffect(() => {
    FetchTransactions(1)
    // eslint-disable-next-line
  }, [])
  const paginationClick = (num) => {
    setLoading(true)
    FetchTransactions(num)
  }
  function navigateToTransaction(id) {
    navigate(`/admin/transaction/${id}`)
  }

  return loading ? (
    <Loader location='center' />
  ) : (
    <div className='home_page'>
      <div className='home_tag'>
        <h1>Transactions</h1>
      </div>
      <div className='home_tables'>
        <div className='left' style={{ minHeight: '60vh' }}>
          <table>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Date</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {transactionsData.transactions.map((item, i) => (
                <tr key={item._id}>
                  <td>{i + 1}</td>
                  <td>${item.amount.toLocaleString()}</td>
                  <td className='count'>{item.type}</td>
                  <td className='count'>
                    {new Date(item.createdAt).toDateString()}, {''}
                    {new Date(item.createdAt).toLocaleTimeString()}
                  </td>
                  <td>{item.state}</td>
                  <td>
                    <Icon
                      icon='fluent:open-24-filled'
                      onClick={() => {
                        item.type === 'credit'
                          ? setReceiptData(item)
                          : navigateToTransaction(item._id)
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        currentPage={currentPage}
        totalCount={transactionsData.totalPages}
        pageSize={10}
        onPageChange={(page) => {
          setCurrentPage(page)
          paginationClick(page)
        }}
      />
    </div>
  )
}

export default Transactions
