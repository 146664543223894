import React, { useState, useEffect } from 'react'
import Logo from '../../assets/images/logo.png'
import './NavBar.scss'
import { Link, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { Icon } from '@iconify/react'

function NavBar() {
  const [isSticky, setSticky] = useState(false)
  const [showMenu, setShowMenu] = useState('false')
  const location = useLocation()
  const navigate = useNavigate()
  const [ShowDropdown, setShowDropdown] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY

      if (offset > 50) {
        setSticky(true)
      } else {
        setSticky(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  function toggleMenu() {
    setShowMenu(!showMenu)
  }
  return (
    <div className={isSticky ? 'sticky nav-bar' : 'nav-bar'}>
      <div className={showMenu ? 'nav-cover flex' : 'nav-cover flex show'}>
        <div className='logo flex'>
          <img src={Logo} alt='' />
          <div className='bar'>
            <Icon icon='fa6-solid:bars' onClick={toggleMenu} />
          </div>
        </div>
        <div
          className={ShowDropdown ? 'nav-lists flex long' : 'nav-lists flex'}
        >
          <div className='left'>
            <ul className='flex'>
              <Link to={'/'}>
                <li className={location.pathname === '/' ? 'active' : ''}>
                  PERSONAL
                </li>
              </Link>
              <Link to={'/small-business'}>
                <li
                  className={
                    location.pathname === '/small-business' ? 'active' : ''
                  }
                >
                  SMALL BUSINESS
                </li>
              </Link>
              <Link to={'/commercial'}>
                <li
                  className={
                    location.pathname === '/commercial' ? 'active' : ''
                  }
                >
                  COMMERCIAL
                </li>
              </Link>
              <Link to={'/wealth'}>
                <li className={location.pathname === '/wealth' ? 'active' : ''}>
                  WEALTH
                </li>
              </Link>
            </ul>
          </div>
          <div className='right flex'>
            <a href='mailto:support@mintcapitalallinace.com'>
              <div className='flex cursor'>
                <Icon icon='wpf:ask-question' />
                <p>Support</p>
              </div>
            </a>
            <button className='dif' onClick={() => navigate('/login')}>
              Login
            </button>
            <div className='drop'>
              <button onClick={() => setShowDropdown(!ShowDropdown)}>
                Open an account
                {ShowDropdown ? (
                  <Icon icon='ep:arrow-up-bold' />
                ) : (
                  <Icon icon='ep:arrow-down-bold' />
                )}
              </button>
              {ShowDropdown && (
                <div className='down'>
                  <ul>
                    <li onClick={() => navigate('/sign-up')}>
                      Checking Account
                    </li>
                    <li onClick={() => navigate('/sign-up')}>
                      Online Savings Account
                    </li>
                    <li onClick={() => navigate('/sign-up')}>
                      Money Market Account
                    </li>
                    <li onClick={() => navigate('/sign-up')}>
                      Certificate of Deposit
                    </li>
                    <li onClick={() => navigate('/sign-up')}>
                      IRA Certificate of Deposit
                    </li>
                    <li onClick={() => navigate('/sign-up')}>
                      ira Savings Account
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavBar
